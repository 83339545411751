import React, { useState, useEffect } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import './bgv.css';
import moment from 'moment';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import SignatureCanvas from 'react-signature-canvas';
const BgvAuthorizationConsent = props => {
  const { getSignFun, empDetails, policyData } = props;
  const api = new API();
  const currentDate = moment().format('DD-MM-YYYY');
  const [userName, setUserName] = useState('');
  const [signTrim, setSignTrim] = useState();
  const [sigPad, setsigPad] = useState('');
  const [bgvdata, setBGVdata] = useState('');
  const [userID, setUserID] = useState('');

  useEffect(() => {
    let userData = getLocalDataAsObject('user');
    console.log('user data is', userData);
    if (userData && userData.length > 0) {
      setUserName(userData[0].first_name + ' ' + userData[0].last_name);
      setUserID(userData[0].id);
    }
  }, []);

  const trimSign = () => {
    let trim = sigPad.getTrimmedCanvas().toDataURL('image/png');
    setSignTrim(trim);
    getSignFun(trim);
  };
  const clearSign = () => {
    sigPad.clear();
    setSignTrim('');
  };

  const showBGVDetailsFun = async () => {
    let data = {
      emp_user_id: empDetails?.id ? empDetails?.id : userID,
    };
    let result = await api.get(config.showBGVDetails, true, data);
    if (result && result.code === 200) {
      setBGVdata(result.data[0]);
    } else {
      setBGVdata('');
    }
  };
  useEffect(() => {
    showBGVDetailsFun();
  }, [empDetails, userID]);

  return (
    <div
      className="bgvemail-container"
      style={{
        background: '#f1f1f1',
        borderLeft: '10px solid',
        borderRight: '10px solid',
        border: '10px solid',
        borderImageSlice: '1',
        borderWidth: '3px',
        backgroundRepeat: 'no-repeat',
        borderImageSource: 'linear-gradient(to right, #FF0057,#eb7c45,#2a3c81)',
      }}
    >
      <center>
        <div style={{ margin: '0 auto', background: 'images/' }}>
          <img className="watermarked" src="images/enLogo.png" alt="" />
          <table
            align="center"
            role="presentation"
            cellSpacing="0"
            cellPadding="0"
            border="0"
            width="100%"
            style={{ margin: 'auto' }}
          >
            <img className="watermarked" src="images/enLogo.png" alt="" />
            <tr>
              <td
                valign="top"
                className="bg_white"
                style={{ padding: '1em 2.5em 0 2.5em' }}
              >
                <table
                  role="presentation"
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  width="100%"
                >
                  <tr>
                    <td className="logo" style={{ textAlign: 'center',
                  background:'white'}}>
                      <img src="images/enLogo.png" alt="" style={{height:'120px', width:'270px'}} />
                    </td>
                  </tr>
                </table>
                <hr className="line_rating" />
              </td>
            </tr>
          </table>

          <div>
            <h3>
              <strong>
                <u>UNDERTAKING</u>
              </strong>
            </h3>
          </div>
          {/* <div className="bgvmiddletext">
            <strong>
              <b>To Whom It May Concern</b>
            </strong>
          </div> */}
          <div className="bgvmidtext" style={{ color: 'black' }}>
            <div>
            I, Shri / Mr. / Ms / Mrs / Smt. {" "} 
            <strong  style={{color :"rgb(42, 60, 129)", 
                          textDecoration: "underline",
                          textDecorationStyle: "dotted",
                          fontSize: "large"}}>
              {policyData?.first_name + ' ' + policyData?.last_name}
            </strong>
            {" "} 
            as an employee of EDUNEXTG,
            undertake the followings rules during my tenure of service with the Company.
            </div>
            <br />
            <div>
              I Will;{' '}
            </div>
            <ul>
              <li style={{listStyleType:"upper-greek"}}>
              Maintain high ethical standard and integrity during my tenure with the Company. 
              </li>
              <li style={{listStyleType:"upper-greek"}}>
              Follow probity and rule of law during my tenure with the Company. 
              </li>
              <li style={{listStyleType:"upper-greek"}}>
              Neither take, nor offer bribe.
              </li>
              <li style={{listStyleType:"upper-greek"}}>
              Obey the Company’s rules and regulations. 
              </li>
              <li style={{listStyleType:"upper-greek"}}>
              Perform all tasks in an honest and transparent manner.
              </li>
              <li style={{listStyleType:"upper-greek"}}>
              Always act in Company’s’ interest. 
              </li>
              <li style={{listStyleType:"upper-greek"}}>
              Lead by example exhibiting integrity in personal behavior. 
              </li>
              <li style={{listStyleType:"upper-greek"}}>
              Report any incident of corruption to the appropriate authority. 
              </li>
            </ul>
            <br />
            <div>
            Company can initiate any action against me if I am found guilty in violating the above mentioned undertaking.
            </div>
            <br />

            <div className="bgvinput">
              <strong style={{ color: '#2a3c81' }}>
                Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{' '}
              </strong>
              &nbsp;{policyData?.first_name + ' ' + policyData?.last_name}
              <br />
              <strong style={{ color: '#2a3c81' }}>
                Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{' '}
              </strong>
              &nbsp;
              {bgvdata?.inserted_at ? bgvdata?.inserted_at  : policyData.joining_date
            ? moment(policyData.joining_date).format('DD-MM-YYYY') : currentDate}
              <br />
              <strong style={{ color: '#2a3c81' }}>
                Place &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{' '}
              </strong>
              &nbsp;{policyData?.location}
              <br />
              <strong style={{ color: '#2a3c81' }}>Signature&nbsp; : </strong>
              {bgvdata?.emp_policy_status === 1 ? (
                <img src={bgvdata.filename === null ? '' : bgvdata.filename} height="40" width="90" alt="sign" />
              ) : (
                signTrim && (
                  <img src={signTrim} height="40" width="90" alt="sign" />
                )
              )}
              {(bgvdata?.emp_policy_status === 0 ||
                bgvdata?.emp_policy_status === undefined) && (
                <>
                  <div
                    style={{
                      border: '1px solid',
                      backgroundColor: 'lightgray',
                      width: '250px',
                      height: '150px',
                    }}
                  >
                    <SignatureCanvas
                      penColor="green"
                      canvasProps={{
                        width: 250,
                        height: 150,
                        className: 'sigCanvas',
                      }}
                      ref={ref => {
                        setsigPad(ref);
                      }}
                    />
                  </div>
                  <br />

                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={trimSign}
                  >
                    Sign
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={clearSign}
                  >
                    Clear
                  </button>
                </>
              )}
            </div>
            <hr className="line_rating" />
          </div>
          {/* <div
            role="presentation"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            width="100%"
          >
            <p
              className="text-footer1 pb10"
              style={{
                color: '#000',
                fontFamily: "'Work Sans', sans-serif",
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                paddingBottom: '10px',
              }}
            >
              © Copyright (2021)Powered by{' '}
              <a href="https://www.qdegrees.com/">QDegrees</a>
            </p>

            <p
              className="text-footer2 pb20"
              style={{
                color: '#000',
                fontFamily: "'Work Sans' sans-serif",
                fontSize: '12px',
                lineHeight: '26px',
                textAlign: 'center',
                paddingBottom: '20px',
              }}
            >
              <img src="images/address.svg" alt="" style={{ height: '20px' }} />
              &nbsp; B-9, 1st Floor, Mahalaxmi Nagar, Behind WTP South Block,
              Malviya Nagar, Jaipur, Rajasthan (302017)
            </p>
          </div> */}
        </div>
      </center>
    </div>
  );
};

export default BgvAuthorizationConsent;
