import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';
import logos from '../assets/glueple.png';
import icon from '../assets/icon_logo.ico';
import CIcon from '@coreui/icons-react';
import './TheSidebar.css';
// sidebar nav config
import navigation from './_nav';
import { connect } from 'react-redux';
import { commonActions, commonSelectors } from 'src/core/resource/common';

const TheSidebar = props => {
  const { getSidebarOptions } = props;
  const dispatch = useDispatch();
  const show = useSelector(state => state.sidebarShow);
  console.log({ getSidebarOptions });
  return (
    <CSidebar
      show={getSidebarOptions?.show || 'responsive'}
      minimize={getSidebarOptions?.minimize || false}
      onShowChange={val => dispatch({ type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none bg-white">
        <CIcon
          className="c-sidebar-brand-full"
          style={{ display: '' }}
          name="logo-negative"
          src={logos}
          height={45}
          //  width={140}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          src={icon}
          height={35}
          width={30}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};
const mapStateToProps = state => {
  return {
    getSidebarOptions: commonSelectors.getSidebarOptions(state),
  };
};
const mapDispatchToProps = dispatch => ({
  navigateToToggleSidebar: data => dispatch(commonActions.toggleSidebar(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(TheSidebar));
