import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie-player';
import {
  BrowserRouter,
  Redirect,
  Route,
  HashRouter,
  Switch,
  useLocation,
} from 'react-router-dom';
import AdminDashboard from './admin-dashboard';
import NewSignupForm from './admin-dashboard/accounts/new-signup-form';
import HrManagerDashbard from './HR-MANAGER-PANEL';
import HRDashboard from './HR-MANAGER-PANEL/HR_DASHBOARD';
import LandingDashboard from './HR-MANAGER-PANEL/landing_dashboard/LandingDashboard';
import HrDashbard from './HR-PANEL';
import Admin from './Admin-Panel';
import CandidateLibrary from './HR-PANEL/candidateLIbrary';
import './scss/style.scss';
import Dashboard from './views/dashboard/Dashboard';
import BookingMeetingRoom from './views/FMS/fms_management/booking_meeting_room/booking_meeting_room';
import BookingMeetingRoomScreen from './views/FMS/fms_management/booking_meeting_room/booking_meeting_room_screen';
import ScanQR from './views/FMS/fms_management/booking_meeting_room/scanQR';
import MrfApprovedByEmail from './views/mrf_manager/MrfApprovedByEmail';
import OnboardingEmployeeDashboard from './views/ONBOARDING_EMPLOYEE';
import PmsManagement from './views/PMS';
import Test from './views/test';
import { withCookies } from 'react-cookie';
import API from './utils/apiCalling';
import { config } from './utils/apiUrl';
import ModalExtendShiftTime from './HR-MANAGER-PANEL/ModalExtendShiftTime';
import AddNewTemplate from './admin-dashboard/payroll-admin/AddNewTemplate';
import Clearance_form from './views/seperation/clearance_form/Clearance_form';
import Chat from './chat';
import io from 'socket.io-client';
import './chat/chatnotification';
import AdminDashboard360 from './views/dashboard/adminDashboard360';
import HiringDashboard from './views/dashboard/hiringDashboard';
import HeadcountDashboard from './views/dashboard/headcountDashboard';
import DashboardPlain from './views/dashboard/dashboardPlain';
import DashBoard from './Dashboard';
import AddSoftware from './views/ASSETS MANAGEMENT/addSoftware';
import Permissions from './Permissions/Index';
import Messages from './views/messages/messages';
import { mqttActions, mqttSelectors } from 'src/core/resource/mqtt';
import { messageActions, messageSelectors } from 'src/core/resource/messages';
import GuestForm from './reception/guest_form';
import GuestDetails from './reception/guest_details';
import EmployeeForm from './reception/employee_form';
import EmployeeDetails from './reception/employee_details';
import AddHoliday from './views/ATTENDANCE/HOLIDAY/AddHoliday';
import ChatImage from 'src/views/annimation/chatbutton.json';
// import Requestemployeeseparation from '../src/views/seperation/Requestemployeeseparation';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = props => {
  const {
    navigateToConnectMqtt,
    navigateToToggleChatPopup,
    navigateToClearMessageStore,
    getIsChatPopupOpen,
    getUnreadMessageCounts,
  } = props;
  const [socket, setSocket] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  //const ENDPOINT = `https://chat.hrqpro.com:8080?userId=${JSON.parse(getLocalDataAsObject('user')) && JSON.parse(getLocalDataAsObject('user'))[0].id}`;
  // const ENDPOINT = 'http://localhost:8080';
  // const ENDPOINT = `http://localhost:8080?userId=${JSON.parse(getLocalDataAsObject('user'))&&JSON.parse(getLocalDataAsObject('user'))[0].id}`;

  const api = new API();
  const [showChatPopup, setShowChatPopup] = useState(false);
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
  const queryparam = {
    // userId:JSON.parse(getLocalDataAsObject('user')&&getLocalDataAsObject('user').length>0)[0].id,
    // deviceId:getLocalDataAsObject("chatToken")
  };
  const openChatPopup = () => {
    navigateToToggleChatPopup(true);
  };

  const hideChatPopup = () => {
    navigateToToggleChatPopup(false);
  };

  // useEffect(() => {
  //   navigateToClearMessageStore('CLEAR_IS_CHAT_POPUP_OPEN');
  //   navigateToConnectMqtt();
  //   // let socket1 = io(ENDPOINT, {
  //   //   'transports': ['websocket', 'polling'],
  //   //   reconnection: true,
  //   //   EIO:4
  //   // });
  //   // // socket1.on("disconnect",()=>{
  //   // //   console.log("io disconnect");
  //   // //   io(ENDPOINT);
  //   // // })
  //   // let user = (JSON.parse(localStorage?.getItem('user')))?JSON.parse(localStorage?.getItem('user')):null;
  //   // setCurrentUser(user);
  //   // console.log("ddd",socket1, currentUser)
  //   // setSocket(socket1)
  // }, []);

  // useEffect(() => {
  //   if (getUnreadMessageCounts && getUnreadMessageCounts?.data?.counts) {
  //     const counts = getUnreadMessageCounts?.data?.counts;
  //     const objValuesArr = Object.values(counts);
  //     const total =
  //       objValuesArr?.reduce(function(a, b) {
  //         return a + b;
  //       }, 0) || 0;
  //     setTotalUnreadMessages(total);
  //   }
  // }, [getUnreadMessageCounts]);

  let token = props.cookies.get('token');

  const logging_out = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
    props.cookies.remove('token');
    props.cookies.remove('user');
    window.location.href = '/';
  };

  // let Token = props.cookies.get("token")

  // console.log("===========--------------------===================----------", token)
  // let token = getLocalDataAsObject("token") ? getLocalDataAsObject("token") : ""
  let panel_role = props.cookies.get('user');

  let getPanel_role = panel_role && panel_role[0] && panel_role[0].panel_role;

  if (token) {
    return (
      <div>
        <HashRouter>
          <ModalExtendShiftTime />
          <React.Suspense fallback={loading}>
            <Switch>
              <>
                <Route
                  path="/dashboard"
                  name="Home"
                  render={props => <TheLayout {...props} />}
                />
                <Route
                  path="/admin-dashboard"
                  name="AdminDashboard"
                  render={props => <TheLayout {...props} />}
                />
                <Route render={() => <Redirect to="/dashboard" />} />
                <Route
                  path="/hr-dashboard"
                  name="HR Dashboard"
                  render={props => <HrDashbard {...props} />}
                />
                <Route
                  path="/admin"
                  name="Admin Dashboard"
                  render={props => <Admin {...props} />}
                />
                <Route
                  path="/hrms/onboarding"
                  name="onboarding"
                  render={props => <TheLayout {...props} />}
                />
                <Route
                  exact
                  path="/ddashboard"
                  name="landing dashboard"
                  render={props => <LandingDashboard {...props} />}
                />
                <Route
                  path="/hr-manager-dashboard"
                  name="hrmanagerdashboard"
                  render={props => <HrManagerDashbard {...props} />}
                />
                <Route
                  exact
                  path="/messages"
                  name="Chat"
                  render={props => <Messages {...props} />}
                />
                <Route
                  exact
                  path="/mrf-approved/:id/:applying_for"
                  name="MrfApproved"
                  render={props => <MrfApprovedByEmail {...props} />}
                />
                <Route
                  exact
                  path="/dashboard360"
                  name="MrfApproved"
                  render={props => <AdminDashboard360 {...props} />}
                />
                <Route
                  exact
                  path="/hiringDashboard"
                  name="MrfApproved"
                  render={props => <HiringDashboard {...props} />}
                />
                <Route
                  path="/candidate-library"
                  name="Candidate Library"
                  render={props => <CandidateLibrary {...props} />}
                />
                <Route
                  exact
                  path="/dashboardHeadcount"
                  name="MrfApproved"
                  render={props => <HeadcountDashboard {...props} />}
                />
                <Route
                  path="/dashboardWorkforce"
                  name="dashboard"
                  render={props => <DashboardPlain {...props} />}
                />
                <Route
                  exact
                  path="/abc"
                  name="MrfApproved"
                  render={props => <Clearance_form {...props} />}
                />
                <Route
                  exact
                  path="/chat"
                  name="Chat"
                  render={props => <Chat {...props} />}
                />

                <Route
                  exact
                  path="/accounts/new-signup-form"
                  name="AdminDashboardSignupForm"
                  render={props => <NewSignupForm {...props} />}
                />

                <Route
                  path="/taskboard"
                  name="Task Box"
                  render={props => <DashBoard {...props} />}
                />
                <Route
                  path="/permissions"
                  name="Permissions"
                  render={props => <Permissions {...props} />}
                />

                <Route
                  path="/softwareSave"
                  name="Add Software"
                  render={props => <AddSoftware {...props} />}
                />
                <Route
                    path="/guest-form"
                    name="Guest Form"
                    render={props => <GuestForm {...props} />}
                  />
                  <Route
                    path="/employee-form"
                    name="Employee Form"
                    render={props => <EmployeeForm {...props} />}
                  />
                  <Route
                    path="/guest-details"
                    name="Guest Details"
                    render={props => <GuestDetails {...props} />}
                  />
                  <Route
                    path="/employee-details"
                    name="Employee Details"
                    render={props => <EmployeeDetails {...props} />}
                  />
                   <Route
                    exact
                    path="/meeting-room/screen/:id"
                    name="BookingMeetingRoom"
                    render={props => <BookingMeetingRoomScreen {...props} />}
                   />
                    <Route
                    path="/booking-meeting-room/:id"
                    name="BookingMeetingRoom"
                    render={props => <BookingMeetingRoom {...props} />}
                  />
                  {getPanel_role === "onboarding_employee" ?
                  <Route render={() => <Redirect to="/hrms/onboarding" />} /> 
               : getPanel_role === "recruiter" ?
                  <Route render={() => <Redirect to="/hr-dashboard" />} /> 
                : getPanel_role === "hr_manager" ? 
                <Route render={() => <Redirect to="/hr-manager-dashboard" />} /> 
                : getPanel_role === "reception" ?
                  <Route render={() => <Redirect to="/guest-form" />} />
                : <Route render={() => <Redirect to="/dashboard/attendance-management" />} /> 
              }
                {/* <Route render={() => <Redirect to="/guest-form" />} /> */}
              </>
            </Switch>
          </React.Suspense>
        </HashRouter>

        {/* {showChatPopup && currentUser && socket && (
          <Chat socket={socket} currentUser={currentUser} />
        )} */}

        {/* {!getIsChatPopupOpen && (
          <button onClick={openChatPopup} className="chat-main">
            <Lottie
              animationData={ChatImage}
              play
              style={{ width: 150, height: 49 }}
            />
            {totalUnreadMessages > 0 && (
              <span className="msgCount">{totalUnreadMessages}</span>
            )}
          </button>
        )} */}

        {/* {!getIsChatPopupOpen && (
          <button onClick={openChatPopup} className="chat-main">
            <i className="fa fa-3x fa-comments-o " aria-hidden="true"></i>
          </button>
        )} */}

        {/* <Messages
          isShowChatPopup={getIsChatPopupOpen}
          openChatPopup={openChatPopup}
          hideChatPopup={hideChatPopup}
        /> */}
      </div>
    );
  } else {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/test"
              name="test"
              render={props => <Test {...props} />}
            />
            <Route
              exact
              path="/test"
              name="test"
              render={props => <Test {...props} />}
            />
            <Route
              exact
              path="/mrf-approved/:id/:applying_for"
              name="MrfApproved"
              render={props => <MrfApprovedByEmail {...props} />}
            />
            {/* <Route
            exact
            path="/meeting-room/screen/:id"
            name="BookingMeetingRoom"
            render={props => <BookingMeetingRoomScreen {...props} />}
          /> */}
            <Route
              exact
              path="/accounts/new-signup-form"
              name="AdminDashboardSignupForm"
              render={props => <NewSignupForm {...props} />}
            />

            <>
              <Route
                exact
                path="/"
                name="Login Page"
                render={props => <Login {...props} />}
              />

              <Route path="*" render={() => <Redirect to="/" />} />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={props => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />

              {/* <Route render={() => (<Redirect to='/' />)} /> */}
              {/* <Route
                exact
                path="/dashboard/separation/requestemployeeseparation"
                name="Request employee separation"

              /> */}
              {/* <Requestemployeeseparation/> */}
            </>
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
};

const mapStateToProps = state => {
  return {
    mqttClientInstance: mqttSelectors.getMqttClientInstance(state),
    getIsChatPopupOpen: messageSelectors.getIsChatPopupOpen(state),
    getUnreadMessageCounts: messageSelectors.getUnreadMessageCountsRes(state),
  };
};
const mapDispatchToProps = dispatch => ({
  navigateToConnectMqtt: () =>
    dispatch(mqttActions.mqttClientInstanceRequest()),
  navigateToClearMessageStore: data =>
    dispatch(messageActions.handleClearMessageStore(data)),
  navigateToToggleChatPopup: data =>
    dispatch(messageActions.toggleChatPopup(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withCookies(App));
