/* eslint-disable react/react-in-jsx-scope */
import { CButton } from '@coreui/react';
import { useState } from 'react';
import HoldMrfModal from 'src/HR-PANEL/pending_requisition/holdMrfModal';
import API from 'src/utils/apiCalling';
import swal from 'sweetalert';
import { config } from '../utils/apiUrl';
import './taskbox.css';
import Alert from 'src/alert/alert';
import DeclineModal from 'src/modals/DeclineModal';
import ApprovalConfirmModal from 'src/views/ATTENDANCE/modals/ApprovalConfirmModal';
import { swalWithTextarea } from 'src/common/sweetAlert';
import JustificationModal from 'src/views/ASSETS MANAGEMENT/justificationModal';
import JustificationModalForHr from 'src/HR-MANAGER-PANEL/received_requisition/justificationModal';
import { useEffect } from 'react';
import SeparationBtn from './ActionBtns/SeparationBtn';
import AssetsBtn from './ActionBtns/AssetsBtn';
import ApproveMrfByManager from './ActionBtns/ApproveMrfByManager';
import { toast, ToastContainer } from 'react-toastify';
const ActionBtn = ({ item, bodyData, reloadTaskBox, tabs }) => {
  const api = new API();
  const [isHoldMRFModalOpen, setIsHoldMRFModalOpen] = useState(false);
  const [sendMrfData, setSendMrfData] = useState();
  const [declineComment, setDeclineComment] = useState();
  const [isDeclinedComment, setIsDeclinedComment] = useState();
  const [declinePopup, setDeclinePopup] = useState(false);
  const [isOpenJustification, setIsOpenJustification] = useState(false);
  const [assetsDone, setAssetsDone] = useState(false);
  const [isShowRejectConfirmModal, setIsShowRejectConfirmModal] = useState(
    false,
  );
  const [isOpenJustificationHr, setIsOpenJustificationHr] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedMrfDetails, setSelectedMrfDetails] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [isShowApproveConfirmModal, setIsShowApproveConfirmModal] = useState(
    false,
  );
  const [currentSelectedItem, setCurrentSelectedItem] = useState(null);
  const [currentSelectedAction, setCurrentSelectedAction] = useState(null);
  const [assetRequestData, setAssetRequestData] = useState();

  useEffect(() => {
    reloadTaskBox();
  }, [assetsDone]);

  const isJustificationModalOpen = data => {
    setAssetRequestData(data);
    setIsOpenJustification(!isOpenJustification);
  };

  const isJustificationModalHROpen = mrf => {
    setSelectedMrfDetails(mrf);
    setIsOpenJustificationHr(!isOpenJustificationHr);
  };

  const rejectMrf = juctificationComment => {
    let data = {
      mrf_id: selectedMrfDetails && selectedMrfDetails.id,
      mrf_applying_for: selectedMrfDetails && selectedMrfDetails.applying_for,
      hr_rejection_comment: juctificationComment,
    };
    // return
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to reject this MRF`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Reject'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.rejectMrfByHrManager, data);
        if (result && result.code == 200) {
          toast.success('MRF Rejected Successfully');
          isJustificationModalOpen(selectedMrfDetails);
          setAssetsDone(!assetsDone);
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };

  const pendingRequisitionBtn = item => {
    return (
      <div className="dropdown-menu">
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            holdMRF(item);
          }}
        >
          Hold MRF
        </CButton>
      </div>
    );
  };

  const mrfBtn = item => {
    return (
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={e => {
            AcceptApproveStatus(e, item);
          }}
        >
          Approve
        </CButton>
        <div className="dropdown-divider"></div>
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            declinePopupStatus(item);
          }}
        >
          Reject
        </CButton>
      </div>
    );
  };
  const approveMrfHr = item => {
    return (
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={e => {
            ApprovedMrfByManager(item);
          }}
        >
          Approve
        </CButton>
        <div className="dropdown-divider"></div>
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            isJustificationModalHROpen(item);
          }}
        >
          Reject
        </CButton>
      </div>
    );
  };

  const ApprovedMrfByManager = mrf => {
    let data = {
      mrf_id: mrf && mrf.id,
      mrf_applying_for:
        mrf && mrf.applying_for === 'Replacement'
          ? 'replacement'
          : 'New Hiring'
          ? 'vacancy'
          : ' ',
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to approve this MRF`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Approve'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.ApprovedMrfByManager, data);
        if (result && result.code == 200) {
          toast.success('MRF Approved Successfully');
          // isJustificationModalOpen(selectedMrfDetails)
          setAssetsDone(!assetsDone);
        } else {
          toast.error('TEST');
        }
      }
    });
  };

  const attendanceBtn = item => {
    return (
      <div className="dropdown-menu">
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            handleOnActionBtnClick(item, 'approve');
          }}
        >
          Approve
        </CButton>
        <div className="dropdown-divider"></div>
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            handleOnActionBtnClick(item, 'reject');
          }}
        >
          Reject
        </CButton>
      </div>
    );
  };
  const payslipBtn = item => {
    return (
      <div className="dropdown-menu">
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            approveRejectPayslipRequest(item, 1);
          }}
        >
          Approve
        </CButton>
        <div className="dropdown-divider"></div>
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            approveRejectPayslipRequest(item, 0);
          }}
        >
          Reject
        </CButton>
      </div>
    );
  };

  const approveRejectPayslipRequest = async (item, value) => {
    let payload = {
      query_type: 'manager_approval',
      value: value,
      id: item?.id,
    };
    let result = await api.post(config.downloadPayslipRequest, payload);
    if (result && result.code === 200) {
      if (value === 0) {
        toast.success('Payslip Request Rejected');
      } else {
        toast.success('Payslip Request Approved');
      }
      reloadTaskBox();
    }
  };

  const handleOnActionBtnClick = (item, action) => {
    if (item.type === 'attendance_correction') {
      handleOpenActionConfirmModal(item, action);
    } else {
      attendanceApproveRejectionSubmition(item.type, action, item.id);
    }
  };
  const handleOpenActionConfirmModal = (item, action) => {
    setCurrentSelectedItem(item);
    setCurrentSelectedAction(action);
    if (action === 'approve') setIsShowApproveConfirmModal(true);
    if (action === 'reject') setIsShowRejectConfirmModal(true);
  };

  const attendanceApproveRejectionSubmition = async (
    application_type,
    is_approved,
    id,
  ) => {
    let isGoToNext = false;
    const data = {
      type: application_type,
      is_approved: is_approved,
      id: id,
    };
    if (is_approved === 'reject') {
      const swalRes = await swalWithTextarea(
        `Are you sure to ${is_approved}`,
        '',
        'Enter Reason',
      );
      data.comment = swalRes;
      isGoToNext = swalRes ? true : false;
    } else if (is_approved === 'approve') {
      const swalRes = await swalWithTextarea(
        `Are you sure to ${is_approved}`,
        '',
        'Enter Reason',
      );
      data.comment = swalRes;
      isGoToNext = swalRes ? true : false;
    }
    if (isGoToNext) {
      handleSaveApprovalRejection(data);
    } else {
      // toast.error('Your request is not completed');
    }
  };

  const closeConfirmModal = () => {
    setIsShowApproveConfirmModal(false);
    setIsShowRejectConfirmModal(false);
  };

  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };

  const isAlertToggleClose = () => {
    setIsAlert(false);
  };

  const handleSaveApprovalRejection = async data => {
    setIsLoading(true);
    let result = await api.post(config.approveRejectAttendance, data);
    if (result && result.code === 200) {
      toast.success(result && result.message);
      closeConfirmModal();
      // getApprovalAndAppliedRequest(
      //   data.type,
      //   'approval',
      // );
      reloadTaskBox();
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const declinePopupStatus = async dataDeclined => {
    setDeclineComment(dataDeclined);
    setDeclinePopup(!declinePopup);
  };

  const declineApprovedStatus = async () => {
    let data = {
      id: declineComment && declineComment.id,
      applying_for: declineComment && declineComment.applying_for,
      is_declined_comment: isDeclinedComment || '',
    };
    if(!data?.is_declined_comment) {
      toast.error('Please enter valid reason.');
    }
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to Decline MRF`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.declineapprovalmrf, data, true);
        if (result && result.code == 200) {
          setDeclinePopup(!declinePopup);
          toast.success('Mrf Rejected Successfully');
          reloadTaskBox();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };

  const AcceptApproveStatus = async (e, dataApproved) => {
    e.preventDefault();
    let data = {
      id: JSON.stringify(dataApproved.id),
      applying_for: dataApproved.applying_for,
    };

    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to approve`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Approve'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.acceptapprovalmrf, data, true);
        if (result && result.code == 200) {
          toast.success('Successfully approved');
          reloadTaskBox();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };

  const holdMRF = async mrf => {
    setSendMrfData(mrf);
    setIsHoldMRFModalOpen(!isHoldMRFModalOpen);
    reloadTaskBox();
  };

  return (
    <div className="btn-group float-right">
      <button
        className="blueBtn"
        data-toggle="dropdown"
        id="dropdownMenuButton"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Action
      </button>
      {item?.tab_name === 'pending_reqs' && pendingRequisitionBtn(item)}
      {item?.tab_name === 'mrf' && mrfBtn(item)}
      {item?.tab_name === 'attendance' && attendanceBtn(item)}
      {item?.tab_name === 'asset_request' && (
        <AssetsBtn item={item} reloadTasBox={reloadTaskBox} />
      )}
      {item?.tab_name === 'approve_mrf_hr' && (
        <ApproveMrfByManager
          item={item}
          isAlertToggle={isAlertToggle}
          toast={toast}
          reloadTaskBox={reloadTaskBox}
        />
      )}
      {item?.tab_name === 'separation_approval' && (
        <SeparationBtn item={item} reloadTaskBox={reloadTaskBox} />
      )}
      {item?.tab_name === 'payslip_approval' && payslipBtn(item)}
      <HoldMrfModal
        holdMRF={holdMRF}
        sendMrfData={sendMrfData}
        isHoldMRFModalOpen={isHoldMRFModalOpen}
        bodyData={bodyData}
        reloadTaskBox={reloadTaskBox}
      />
      <DeclineModal
        declinePopup={declinePopup}
        setDeclinePopup={setDeclinePopup}
        declineApprovedStatus={declineApprovedStatus}
        setIsDeclinedComment={setIsDeclinedComment}
        isDeclinedComment={isDeclinedComment}
      />
      <ApprovalConfirmModal
        isShow={isShowApproveConfirmModal || isShowRejectConfirmModal}
        title={`Attendance Correction ${
          isShowApproveConfirmModal ? 'Approval' : 'Rejection'
        }`}
        data={currentSelectedItem}
        currentSelectedAction={currentSelectedAction}
        closeConfirmModal={closeConfirmModal}
        handleOnSubmit={handleSaveApprovalRejection}
        isLoading={isLoading}
      />
      <Alert
        isAlert={isAlert}
        isAlertToggle={isAlertToggle}
        alertMessage={alertMessage}
        isAlertToggleClose={isAlertToggleClose}
      />
      <JustificationModalForHr
        isOpenJustification={isOpenJustificationHr}
        isJustificationModalOpen={isJustificationModalHROpen}
        rejectMrf={rejectMrf}
      />
      <ToastContainer />
    </div>
  );
};

export default ActionBtn;
