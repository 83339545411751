import React from 'react';
import { CDataTable } from '@coreui/react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';

const AttendanceCorretionAppliedList = props => {
  const { data, type, renderDataStatus } = props;
  const fields = [
    {
      label: 'S.No.',
      key: 's_no',
      sorter: true,
      filter: true,
    },
    {
      label: 'Correction For',
      key: 'date',
      sorter: false,
      filter: false,
    },
    {
      label: 'Check IN Time',
      key: 'corrected_check_in_time',
      sorter: true,
      filter: true,
    },
    {
      label: 'Check OUT Time',
      key: 'corrected_check_out_time',
      sorter: false,
      filter: false,
    },
    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Approval For',
      key: 'approve_for',
      sorter: false,
      filter: false,
    },
    {
      label: 'Created Date',
      key: 'correction_request_raised_at',
      sorter: true,
      filter: true,
    },
  ];
  
  return (
    <CDataTable
    items={data}
    fields={fields}
    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
    itemsPerPageSelect
    itemsPerPage={5}
    hover
    sorter
    pagination
    scopedSlots={{
      status: item => <td>{renderDataStatus(item, type)}</td>,
      date: item => (
        <td>{convertDate(item.date, 'DD-MM-YYYY')}</td>
      ),
      correction_request_raised_at: item => (
        <td>{convertDate(item.correction_request_raised_at, 'DD-MM-YYYY')}</td>
      ),
      s_no: (item, index) => (
        <td>
          <b>{index + 1}</b>
        </td>
      ),
      reason: item => (
        <td>
          {item.correction_reason !== null
            ? item.correction_reason
            : item.requested_check_in_reason
            ? item.requested_check_in_reason
            : item.requested_check_out_reason}
        </td>
      ),
    }}
  />
  );
};

export default AttendanceCorretionAppliedList;
