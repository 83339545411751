/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CRow,
} from '@coreui/react';

import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import BgvAuthorizationConsent from './other/bgv_authorization_consent';
import { toast, ToastContainer } from 'react-toastify';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import { useHistory } from 'react-router'
import { Cookies } from 'react-cookie';

const PolicyDetails = props => {
  const history=useHistory()
  const api = new API();
  const { getEmployeeDetailStatus, empStatus, empDetails } = props;
  const [accordion, setAccordion] = useState(0);
  const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);
  const [policy3, setPolicy3] = useState(false);
  const [policy4, setPolicy4] = useState(false);
  const [policy5, setPolicy5] = useState(false);
  const [policy6, setPolicy6] = useState(false);
  const [policy7, setPolicy7] = useState(false);
  const [policy8, setPolicy8] = useState(false);
  const [policy9, setPolicy9] = useState(false);
  const [policy10, setPolicy10] = useState(false);
  const [policy11, setPolicy11] = useState(false);
  const [policy12, setPolicy12] = useState(false);
  const [policy13, setPolicy13] = useState(false);
  const [policy14, setPolicy14] = useState(false);
  const [policy15, setPolicy15] = useState(false);
  const [policy16, setPolicy16] = useState(false);
  const [policy17, setPolicy17] = useState(false);
  const [policy18, setPolicy18] = useState(false);
  const [policy19, setPolicy19] = useState(false);
  const [allPolicyAccept, setAllPolicyAccept] = useState(true);
  const [userSign, setUserSign] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [policyData, setPolicyData] = useState('');
  const [userID, setUserID] = useState('');
  const [joiningDate, setJoiningDate] = useState('');

  useEffect(() => {
    let userData = getLocalDataAsObject('user');
    if (userData && userData.length > 0) {
      setUserEmail(userData[0].email);
      setUserID(userData[0].id);
    }
  }, []);
    const Policyaccepted = async () => {
    if (!userSign) {
      toast.warning('Please sign');
      return;
    }
    let data = {};
    if (empDetails && empDetails.id) {
      data = {
        empPolicyAccept: allPolicyAccept,
        user_id: empDetails && empDetails.id,
        sign: userSign,
      };
    } else {
      data = {
        empPolicyAccept: allPolicyAccept,
        sign: userSign,
      };
    }

    let result = await api.post(config.employeePolicy, data);
    if (result && result.code === 200) {
      toast.success(result && result.message)
      getEmployeeDetailStatus();
        window.location.href = '/#/';
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        Cookies.remove('token');
        Cookies.remove('user');

    } else {
      toast.error(result && result.message);
    }
  };

  const dataForPolicy = async () => {
    let data = {
      emp_user_id: empDetails?.id ? empDetails?.id : userID,
    };
    let result = await api.get(config.allDataforPolicy, true, data);
    if (result && result.code === 200) {
      setPolicyData(result.data[0]);
      setJoiningDate(result?.data?.[0]?.joining_date || moment().format('YYYY-MM-DD'));
    } else {
      toast.error(result && result.message)
      // alert(result && result.message);
    }
  };
  useEffect(() => {
    dataForPolicy();
  }, [userID, empDetails]);

  const acceptpolicy = (e, id) => {
    switch (id) {
      case 1:
        setPolicy1(!policy1);
        break;
      case 2:
        setPolicy2(!policy2);
        break;
      case 3:
        setPolicy3(!policy3);
        break;
      case 4:
        setPolicy4(!policy4);
        break;
      case 5:
        setPolicy5(!policy5);
        break;
      case 6:
        setPolicy6(!policy6);
        break;
      case 7:
        setPolicy7(!policy7);
        break;
      case 8:
        setPolicy8(!policy8);
        break;
      case 9:
        setPolicy9(!policy9);
        break;
      case 10:
        setPolicy10(!policy10);
        break;
      case 11:
        setPolicy11(!policy11);
        break;
      case 12:
        setPolicy12(!policy12);
        break;
      case 13:
        setPolicy13(!policy13);
        break;
      case 14:
        setPolicy14(!policy14);
        break;
      case 15:
        setPolicy15(!policy15);
        break;
      case 16:
        setPolicy16(!policy16);
        break;
      case 17:
        setPolicy17(!policy17);
        break;
      case 18:
        setPolicy18(!policy18);
        break;
        case 19:
        setPolicy19(!policy19);
        break;
      default:
      // console.log("something went wrong")
    }
    // isAllPolicyAccept()
  };

  const getSignFun = sign => {
    setUserSign(sign);
  };
  const sendOtp = async () => {
    let data = {
      email: userEmail,
      type: 'other',
      subject: 'OTP Verification for Policy',
    };
    let result = await api.post(config.sendOtp, data);
    // console.log("Result>>>>>>>>>", result)
    if (result && result.code === 200) {
      alert(result.message);
      setIsOtpSent(true);
    } else {
      alert(result && result.message);
    }
  };
  const verifyOTP = async () => {
    let data = {
      email: userEmail,
      otp: otp,
    };
    let result = await api.post(config.verifyOtp, data);
    if (result && result.code === 200) {
      alert(result.message);
      setIsVerified(true);
      // Policyaccepted();
    } else {
      alert(result && result.message);
    }
  };

  return (
    <CRow>
      <CCol xl="12">
        {true && (
          <CCard>
            <div className="text-center">
              <strong>Company Policies</strong>
              <br />
              <small>
                {' '}
                Kindly read all the policies by clicking respective one's
              </small>
              <p style={{padding:"0px 20px"}}>These rules and regulations will come into effect from 01.04.2024 & is applicable to all employees of the Company irrespective of their hierarchy.	  
              </p>
            </div>

            <CCardBody>
              <div id="accordion">
                <CCard className="mb-0">
                  <CCardHeader id="headingOne">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 0 ? null : 0)}
                    >
                      <h5 className="m-0 p-0 ">
                       Office Timing
                        <i
                          className={
                            accordion === 0
                              ? 'fa fa-angle-double-up fa-lg text-black'
                              : 'fa fa-angle-double-down fa-lg text-black'
                          }
                          style={{ float: 'right' }}
                        ></i>
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 0}>
                    <CCardBody>
                    This is the Office Timing policy.
                      <ul>
                        {' '}
                        <li style={{ listStyleType: 'disc' }}>
                        The Company's working hours are <b>Monday to Saturday, from 09:30 a.m. to 6.30 p.m.</b>  Employees are entitled for <b>30 minutes </b>
                        lunch break on the working days. The employees who are posted in the respective schools, their working hours will be guided by the concerned schools’ working hour. 
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Lunch hour is from 1:30 p.m. to 2:00 p.m..
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Employees have to submit their attendance through the web portal and after submitting the attendance, employees should upload the screenshot in the office WhatsApp group. Employees should raise their attendance after reaching the office premises. Otherwise, it will be marked as absent for that particular day.{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Employees have to submit their exit time through the official portal and after submitting the out time employees should upload the screenshot in the office WhatsApp group. Employees should raise their exit time before leaving the office premises.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        For the <b>field staff</b>, at first they have to follow the above rules and along with that they also have to upload their current location in the office WhatsApp group after reaching the client’s place and also before leaving the client’s place.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        The Company follows no work and no pay policy across its presence.  
                        </li>
                        <li style={{ listStyleType: 'disc' }} >
                        The employees can be relocated at any part of India at the sole discretion of the Company. Refusal of such order shall be considered as insubordination.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 1)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingOne">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 1 ? null : 1)}
                    >
                      <h5 className="m-0 p-0 ">
                       Other Discipline
                        <i
                          className={
                            accordion === 1
                              ? 'fa fa-angle-double-up fa-lg text-black'
                              : 'fa fa-angle-double-down fa-lg text-black'
                          }
                          style={{ float: 'right' }}
                        ></i>
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 1}>
                    <CCardBody>
                    
                      <ul>
                        {' '}
                        <li style={{ listStyleType: 'disc' }} >
                       <b> Usage of Mobile Phone:-</b>
                            <li className='m-2' style={{ listStyleType: 'circle' }}>All employees have to keep their mobile on silent / vibrate mode during office hours and they can use mobiles for calling or texting and other uses only at lunchtime and tea break except on emergency.                                                              </li>
                              </li>
                        <li style={{ listStyleType: 'disc' }} >
                       <b> Dress Code:-</b>
                       <li className='m-2' style={{ listStyleType: 'circle' }} >
                       Employees must wear Company ID Card during office time including field visits.
                       </li>
                       <li className='m-2' style={{ listStyleType: 'circle' }} >
                       Employees must wear a formal dress before entering the office premises and also into the client premises. 
                       </li>
                       <li className='m-2' style={{ listStyleType: 'circle' }} >
                       During office meeting, employees must wear office T-shirt. Jeans & Company t-shirt is allowed to wear on Saturday only.
                       </li>
                       
                        </li>
                      <li style={{ listStyleType: 'disc' }} >
                       <b> Behaviour:-</b>
                            <li className='m-2' style={{ listStyleType: 'circle' }}>
                            Employees should behave appropriately among themselves as well as with the client. Any kind of misbehaviour will not be tolerated by the Company. 
                           </li>
                           <li className='m-2' style={{ listStyleType: 'circle' }}>
                           Un-parliamentary / abusive / offensive languages / obscene gestures are strictly prohibited in the office, clients’ premises, amongst the colleagues. Such type of instance is highly detrimental to the Company’s image. On reporting of such type of instances, the Company will deal the case with priority & employees may lose their job.
                           </li>
                           <li className='m-2' style={{ listStyleType: 'circle' }}>
                           Employees should maintain cleanliness of their respective desk.
                           </li>
                           <li className='m-2' style={{ listStyleType: 'circle' }}>
                           No one should order their colleagues unless they get any specific instruction from their reporting authority to do so.
                           </li>
                      </li>
                      <li style={{ listStyleType: 'disc' }} >
                       <b> Work Details:-</b>
                       <li className='m-2' style={{ listStyleType: 'circle' }}> 
                       Employees should follow their assigned duties and should try to achieve the same within the stipulated time.
                       </li>
                       </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 2)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingOne">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 2 ? null : 2)}
                    >
                      <h5 className="m-0 p-0 ">
                     Guidelines For Leave
                        <i
                          className={
                            accordion === 2
                              ? 'fa fa-angle-double-up fa-lg text-black'
                              : 'fa fa-angle-double-down fa-lg text-black'
                          }
                          style={{ float: 'right' }}
                        ></i>
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 2}>
                    <CCardBody>
                    
                      <ul>
                        {' '}
                        <li style={{ listStyleType: 'disc' }} >
                        Every employee is entitled for 1 day Earn Leave per every working month after the expiry of the said working month. An employee can take maximum 02 Earn Leaves at one time.  Carry forward of the Earn Leaves to next financial year will not be allowed. If anyone takes leave before and after a pre declared holiday, then the holiday/s is/are also considered as leave & accordingly, their leave will be adjusted.     
                        </li>
                        <li style={{ listStyleType: 'disc' }} >
                        HR or concerned reporting authority should sanction any Leave (Except on medical grounds) in advance.
                        </li>
                        <li style={{ listStyleType: 'disc' }} >
                        Wherever logical, applications for leave will be approved, however, the company needs to take into account work requirements and the performance of the employee's duties when considering leave. For example, if another employee has already applied leave at that time, in a small team it may not be possible to allow two people to take leave at the same time.
                        </li>
                        <li style={{ listStyleType: 'disc' }} >
                        If anybody needs to take a leave on an urgent basis on the same day, he/ she should intimate about the same only to their respective reporting authority by email or should use the company WhatsApp group to inform about leave before 09:00 am. <b> Every uninformed leave will be counted as double unpaid leave.</b>
                        </li>
                        <li style={{ listStyleType: 'disc' }} >
                        Submission of leave application is mandatory. <b> Every uninformed leave will be counted as double unpaid leave.</b>
                        </li>
                        <li style={{ listStyleType: 'disc' }} >
                        The Reporting Authority / HR has the right to refuse a leave application submitted to him/her by an employee, depending upon work exigencies.
                        </li>
                       
                       
                       
                      
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 3)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                {/* <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 1 ? null : 1)}
                    >
                      <h5 className="m-0 p-0 ">
                        HSW Policy{' '}
                        <i
                          className={
                            accordion === 1
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 1}>
                    <CCardBody>
                      This is the statement of general policy and arrangements
                      for all our employees emphasizing in their health and
                      safety. This will be a Day-to-day responsibility for
                      ensuring this policy is put into practice, and
                      non-adherence to the HSW policy would lead to strict
                      action and might call for termination from the service
                      with immediate effect.
                      <br />
                      <strong>Essentials while driving/riding - </strong>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          Every employee is required to wear helmet if he/she is
                          on a two wheeler.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Every employee is required to have the seat belt of
                          the car fasten while driving.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Avoid the use of cell phones while driving/riding a
                          vehicle.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Avoid over speeding of vehicles.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Follow the traffic rules while driving/riding vehicle.
                        </li>
                      </ul>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 2)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingThree">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 2 ? null : 2)}
                    >
                      <h5 className="m-0 p-0 ">
                        Conflict of Interest{' '}
                        <i
                          className={
                            accordion === 2
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 2}>
                    <CCardBody>
                      <strong>A conflict of interest occurs when -</strong>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          <strong>Personal interest-</strong> The personal
                          interest of any member of the Board of Directors or
                          the Senior Management interferes or appears to
                          interfere in any way with the interest of the Company.
                          Although this duty does not prevent them from engaging
                          in personal transactions and investments, it does
                          demand that they avoid situations where conflict of
                          interest might occur or appear to occur.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <strong>Involvement/Interference -</strong> The
                          Non-Executive Directors and the Senior Management are
                          expected to devote their attention to the business
                          interest of the Company. They are prohibited from
                          engaging in any activity that interferes with their
                          performance or responsibilities to the Company or
                          otherwise is in conflict with or prejudicial to the
                          Company.{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <strong>Business Interests -</strong> If any member of
                          the Board of Directors or the Senior Management
                          considers investing in securities issued by the
                          Company's customer, supplier or competitor, they
                          should ensure that these investments do not compromise
                          their responsibilities to the Company. Many factors
                          including the size and nature of the investment; their
                          ability to influence the Company's decisions, their
                          access to confidential information of the Company, or
                          of the other entity, and the nature of the
                          relationship between the Company and the customer,
                          supplier or competitor should be considered in
                          determining whether a conflict exists. Additionally,
                          they should disclose to the Company any interest that
                          they have which may conflict with business of the
                          Company.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 3)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard> */}
                <CCard className="mb-0">
                  <CCardHeader id="headingFour">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 3 ? null : 3)}
                    >
                      <h5 className="m-0 p-0 ">
                      Daily Report{' '}
                        <i
                          className={
                            accordion === 3
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 3}>
                    <CCardBody>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                        Employees must submit their daily work report in the official portal and then upload a screenshot in the office WhatsApp group.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          If anyone forgets to upload the report and the screenshot in the portal and group respectively, it will be marked as absent. 
                        </li>
                     
                      </ul>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 4)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 4 ? null : 4)}
                    >
                      <h5 className="m-0 p-0 ">
                      Salary Policy{' '}
                        <i
                          className={
                            accordion === 4
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 4}>
                    <CCardBody>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                        Any increment or declaration of increment shall be made at the sole and absolute discretion of the Company. In determining the amount of bonus, the Company shall consider work performance, with other parameters.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Salary time is from 7th to 15th of every month i.e. Salary for the period of the 1st to the 30th of every month will be disbursed between 7th and 15th of next month. Ex. Salary of June Month From 1st July to 30th July will be paid between 7th and 15th of July month.{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Salary calculation is being done considering 30 Days in every month.{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        If anyone disobeys any office rule/s the salary shall be withheld for that particular employee for the same month.{' '}
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 5)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 5 ? null : 5)}
                    >
                      <h5 className="m-0 p-0 ">
                      Conveyance{' '}
                        <i
                          className={
                            accordion === 5
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 5}>
                    <CCardBody>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Employees shall submit their conveyance daily or tour basis using the prescribed format provided by the company. Employees shall also submit the bills, tickets, etc. in conveyance format.{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Conveyance shall be cleared by the company weekly basis.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Conveyance should be submitted to the Accounts Department.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 6)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 6 ? null : 6)}
                    >
                      <h5 className="m-0 p-0 ">
                      Termination And Notice{' '}
                        <i
                          className={
                            accordion === 6
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 6}>
                    <CCardBody>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                        Upon confirmation of your employment, either employee or employer, can terminate the contract by serving <b> one (1)</b> month <b> notice to either side </b>.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        The Company reserves the right not to give any reasons for termination.{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        The Company will do the FNF of any employee within 120 days after submitting all the official materials and documents.{' '}
                        </li> 
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 7)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 7 ? null : 7)}
                    >
                      <h5 className="m-0 p-0 ">
                      Misplaced Official Documents And materials{' '}
                        <i
                          className={
                            accordion === 7
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 7}>
                    <CCardBody>
                     
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                        Employees should take care of the equipment (materials, documents, etc.) allotted to them on behalf of the company.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        If any equipment is misplaced from employee’s end then only the concerned employee will be held responsible for that.{' '}
                        </li>{' '}
                        <li style={{ listStyleType: 'disc' }}>
                        If any materials are damaged by any employee then he/she will be answerable for that to the competent authority.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        In any case of damage or misplacement, the company will deduct the penalty cost from the salary for the same month of that particular employee.
                        </li> 
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 8)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 8 ? null : 8)}
                    >
                      <h5 className="m-0 p-0 ">
                      Misconduct{' '}
                        <i
                          className={
                            accordion === 8
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 8}>
                    <CCardBody>
                    Without prejudice to the generality of the term ‘misconduct’, the following acts of omission and commission shall be treated as misconduct. On reporting of any of the following misconducts, the Company shall deal with it strictly:-
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                        Theft, fraud or dishonesty in connection with business or property of the Company or of property of another person within the premises of the Company.{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Taking or giving bribes or any illegal gratification.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Furnishing false information regarding name, age, father’s name, qualification, ability or previous service or any other matter germane to the employment at the time of employment or during the course of employment.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Acting in a manner prejudicial to the interest of the Company.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Willful insubordination or disobedience whether or not in combination with others, of any lawful and reasonable order of his/her superior..
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Habitual late or irregular attendance.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Neglect of work or negligence in the performance of duty including malingering or slowing down of work.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Damage to any property of the Company.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Interference or tampering with any safety devices installed in or about the premises of the Company.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Drunkenness or riotous or disorderly or indecent behaviour in the premises of the Company or outside such premises where such behaviour is related to or connected with the employment.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Gambling within the premises of the establishment.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Sleeping while on duty.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Commission of any act which amounts to a criminal offence involving moral turpitude.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Absence from the employee’s appointed place of work without permission or sufficient cause.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Commission of any acts subversive of discipline or of good behaviour.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Abetment of or attempt at abetment of any act which amounts to misconduct.
                        </li>

                      </ul>{' '}
                      Note: The above instances of misconduct are illustrative in nature, and not exhaustive.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 9)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 9 ? null : 9)}
                    >
                      <h5 className="m-0 p-0 ">
                      Taking Part In Demonstration{' '}
                        <i
                          className={
                            accordion === 9
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 9}>
                    <CCardBody>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                        No employee of the Company shall engage himself/herself or participate in any demonstration which involves incitement to an offence.
                        </li>
                        
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 10)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 10 ? null : 10)}
                    >
                      <h5 className="m-0 p-0 ">
                      Restrictions On Taking Part In Political Activities {' '}
                        <i
                          className={
                            accordion === 10
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 10}>
                    <CCardBody>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          <b>Employees of the Company are prohibited : </b>
                          <li className='m-2' style={{ listStyleType: 'circle' }}>
                          To be an office-bearer of a political party or an organisation group which takes part in politics. 
                          </li>
                          <li className='m-2' style={{ listStyleType: 'circle' }}>
                           To take part in or assist in any manner in any movement/ agitation or demonstration of a political nature.  
                          </li>
                          <li className='m-2' style={{ listStyleType: 'circle' }}>
                         To take part in an election to any legislature or local authority.
                          </li>
                          <li className='m-2' style={{ listStyleType: 'circle' }}>
                           To canvass in any election to any legislature or local authority 
                          </li>
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 11)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                {/* <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 11 ? null : 11)}
                    >
                      <h5 className="m-0 p-0 ">
                        Dress-Code Policy{' '}
                        <i
                          className={
                            accordion === 11
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 11}>
                    <CCardBody>
                      <table style={{ border: '10', width: '100%' }}>
                        <tr>
                          <th style={{ border: '1px solid' }}>&nbsp;Days </th>
                          <th style={{ border: '1px solid' }}>
                            &nbsp;Dress Code for Male Employees{' '}
                          </th>
                          <th style={{ border: '1px solid' }}>
                            &nbsp;Dress Code for Female Employees{' '}
                          </th>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Mon- Thu{' '}
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Business Formals, Smart Casuals, Denims{' '}
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Business Formals, Smart Casuals, Denims{' '}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Fri & Sat
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Round Neck T-shirts, Smart Casuals,Denims.
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Round Neck T-shirts, Smart Casuals,Denims.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Not Allowed
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp; T-Shirts with obscene or provoking slogans,
                            Sleeveless T-shirts, &nbsp;Rubber slippers,
                            Floaters.
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp; T-Shirts with obscene or provoking slogans,
                            Sleeveless T-shirts, &nbsp;Rubber slippers,
                            Floaters.
                          </td>
                        </tr>
                      </table>{' '}
                      <strong>
                        ** Employees on field should have formal attire. **{' '}
                      </strong>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 12)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard> */}
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 11 ? null : 11)}
                    >
                      <h5 className="m-0 p-0 ">
                      Terms & Condition:{' '}
                        <i
                          className={
                            accordion === 11
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 11}>
                    <CCardBody>
                    <li style={{listStyleType:'disc'}}>
                    The company follows no work no pay policy.
                    </li>
                    <li style={{listStyleType:'disc'}}>
                    In the event of Work from Home, in case of any exigency at head office (Kolkata Head Office) or client side, concerned employee must visit and complete work. 
                    </li>
                     
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 12)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 12 ? null : 12)}
                    >
                      <h5 className="m-0 p-0 ">
                      Connection With Electronic And Print Media{' '}
                        <i
                          className={
                            accordion === 12
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 12}>
                    <CCardBody>
                      
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                        No employee of the Company shall, except with the previous sanction of the competent authority, own wholly or in part, or conduct or participate in the editing or management of any newspaper or other periodical publication. 
                        </li>
                        <li style={{ listStyleType: 'disc' }}>No employee of the Company shall, except with the previous sanction of the competent authority or the prescribed authority, or in the bonafide discharge of his/her duties, participate in a broadcast or contribute any article or write any letter either in his/her own name or anonymously, pseudonymously, or in the name of any other person to any publication:  </li>
                       
                      </ul>{' '}
                      Provided that no such sanction shall be required if such broadcast or such contribution is of a purely literary, artistic or scientific character.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 13)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 13 ? null : 13)}
                    >
                      <h5 className="m-0 p-0 ">
                      Criticism Of The Company{' '}
                        <i
                          className={
                            accordion === 13
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 13}>
                    <CCardBody>
                    No employee shall, in any electronic and print media or in any document published under his/her name or in the name of any other person or in any communication to the press, or in any public utterances, make any statement : - {' '}
                    <ul>
                        <li style={{ listStyleType: 'disc' }}>
                        Which has the effect of adverse criticism of any policy or action of the Company; or 
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                        Which is capable of embarrassing the relations between the Company and the Client: 
                           </li>
                       
                      </ul>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 14)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                {/* <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 14 ? null : 14)}
                    >
                      <h5 className="m-0 p-0 ">
                        Confirmation Process{' '}
                        <i
                          className={
                            accordion === 14
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 14}>
                    <CCardBody>
                      Probation is the process of managing performance to
                      integrate new employees into the department or Office.
                      Probation is in effect an extension of the selection
                      process. The probationary process must be operated in an
                      open, clear and supportive manner so as to ensure that
                      “full picture” of the employee’s suitability for the
                      employment is obtained.
                      <br /> &#10004; At EduNextG India LLP, every new employee will
                      be on probation for the period of 180 days from the date
                      he or she joins the company. <br /> &#10004; After 180
                      days, there will be an Interim appraisal of the employee.{' '}
                      <br /> &#10004; The interim appraisal of probation will be
                      conducted by the line manager. The purpose of these
                      reviews is to allow the manager to assess whether the
                      employee{' '}
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          meets the conditions set out in the probationary
                          contract{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Work has been performed to a satisfactory standard{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Sick leave record is satisfactory and does not exceed
                          the limits set down for the probationary period{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Attendance, punctuality and conduct are good{' '}
                        </li>
                      </ul>{' '}
                      &#10004; If the appraisal result is satisfactory then
                      status of the employee will be changed to Confirmed
                      employee. <br /> &#10004; In case the appraisal rating is
                      not satisfactory then the employee will be put on a
                      Performance Improvement Plan for 60 days. <br /> &#10004;
                      The objective of PIP is to advise the employee that
                      management would like to help the employee to come up the
                      learning curve. In these 60 days, the line manager would
                      help the employee understanding the KRA’s and objectives
                      and give necessary training. <br /> &#10004; The
                      performance of the employee will be reviewed on weekly
                      basis and feedback will be shared to ensure improvement.
                      <br /> &#10004; At the end of the PIP period if the
                      ratings of the employee are improved then employee will be
                      duly confirmed else the services will be terminated.
                      <br />
                      <br />{' '}
                      <table style={{ border: '1px solid', width: '100%' }}>
                        <tr>
                          <th style={{ border: '1px solid' }}>&nbsp;Legend </th>
                          <th style={{ border: '1px solid' }}>
                            &nbsp;Meaning{' '}
                          </th>
                          <th style={{ border: '1px solid' }}>
                            &nbsp;Implication on Appraisal
                          </th>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid' }}>&nbsp;OS </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Outstanding{' '}
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Confirmed{' '}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid' }}>&nbsp;VG</td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Very Good
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Confirmed{' '}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid' }}>&nbsp;G</td>
                          <td style={{ border: '1px solid' }}>&nbsp;Good </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Confirmed{' '}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid' }}>&nbsp;ME</td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Meet Expectation
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Confirmed{' '}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid' }}>&nbsp;NI</td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Need Improvement
                          </td>
                          <td style={{ border: '1px solid' }}>
                            &nbsp;Unconfirmed{' '}
                          </td>
                        </tr>
                      </table>{' '}
                      <br />
                      <ul>
                        <strong>Timings Policy:- </strong>
                        <li style={{ listStyleType: 'disc' }}>
                          <strong>Office Timings:</strong> Standard punch in
                          time is between 9:30 AM to 10:00 AM and punch out time
                          is between 6:30 PM to 7:00 PM. Total working hours are
                          9 hours which includes 1 hour of breaks.{' '}
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <strong>Lunch: </strong> One break for 30 minutes.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <strong> Tea Breaks: </strong>Two breaks for 15
                          minutes each.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 15)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard> */}
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 14 ? null : 14)}
                    >
                      <h5 className="m-0 p-0 ">
                      Unauthorised Communication Of Information{' '}
                        <i
                          className={
                            accordion === 14
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 14}>
                    <CCardBody>
                    No employee shall, except in accordance with any general or special order of the Company or in the performance in good faith of the duties assigned to him/her, communicate, directly or indirectly, any official document or any part thereof to any officer or other employee, or any other person to whom he/ she is not authorised to communicate such document or information.
                      
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 15)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                {/* <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 15 ? null : 15)}
                    >
                      <h5 className="m-0 p-0 ">
                        Leave Policy{' '}
                        <i
                          className={
                            accordion === 15
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 15}>
                    <CCardBody>
                      An employee is entitled to get-
                      <br /> Privileged Leaves- As per Leave Policy{' '}
                      <ul>
                        {' '}
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          PLs for respective month will get credited on last day
                          of month (28th/30th/31st).{' '}
                        </li>{' '}
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          When worked on any holiday/Week Off for 9 hours (full
                          day) or 4 hours & 45 minutes (half day), you are
                          eligible for comp-off and it will get generated
                          automatically into cosec. **Comp-Off can be availed
                          within 60 days from the date of generation.{' '}
                        </li>{' '}
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Comp-Off& PL cannot be clubbed together.{' '}
                        </li>{' '}
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          PL needs to be applied at least two weeks in advance,
                          and it is subject to an approval from the supervisor.{' '}
                        </li>{' '}
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          PL will be carried forward to the next year{' '}
                        </li>{' '}
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Privileged Leaves will be sanctioned with the approval
                          of the reporting authority.{' '}
                        </li>{' '}
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Every month leaves will get accumulated, and in case
                          the employee does not have leave balance then it will
                          be marked as LWP.{' '}
                        </li>{' '}
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          As per our leave policy, if an employee takes off from
                          his/her duties then Sunday (normal week off) which may
                          come between his leaves will be considered as Leave
                          withoutPay (LWP), if required leave balance is not
                          there.
                        </li>
                      </ul>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 16)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard> */}
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 15 ? null : 15)}
                    >
                      <h5 className="m-0 p-0 ">
                      Canvassing Of Non-Official Or Other Influence{' '}
                        <i
                          className={
                            accordion === 15
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 15}>
                    <CCardBody>
                    No employee shall bring or attempt to bring any outside influence to bear upon any superior authority to further his/her interests in respect of matters pertaining to his/her service in the Company. 
                    
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 16)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 16 ? null : 16)}
                    >
                      <h5 className="m-0 p-0 ">
                      Prohibition of sexual harassment of women{' '}
                        <i
                          className={
                            accordion === 16
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 16}>
                    <CCardBody>
                      {/* An employee is entitled to get-
                      <br /> Privileged Leaves- As per Leave Policy{' '} */}
                      <ul>
                        {' '}
                        <li style={{ listStyleType: 'upper-greek' }}>
                          {' '}
                          No employee shall indulge in any act of sexual harassment of any woman at any work place.
                        </li>{' '}
                        <li style={{ listStyleType: 'upper-greek' }}>
                          {' '}
                          Every employee who is in-charge of a work place shall take appropriate steps to prevent sexual harassment to any woman at the work place.{' '}
                        </li>{' '}
                        
                        

                      </ul>{' '}
                        <b> Explanation (I) For the purpose of this rule :-</b>
                        <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>
                          {' '}
                          “Sexual Harassment” includes any one or more of the following acts or behaviour (whether
                            directly or by implication) namely :-

                          <ul>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            Physical contact and advances; or
                            </li>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            A demand or request for sexual favours; or
                            </li>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            A demand or request for sexual favours; or
                            </li>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            Showing pornography; or
                            </li>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            Any other unwelcome physical, verbal, non-verbal conduct of a sexual nature.
                            </li>
                          </ul>

                        </li>{' '}

                        <li style={{ listStyleType: 'lower-alpha' }}>
                          {' '}
                          The following circumstances, among other circumstances, if it occurs or is present in relation to or connected with any act or behaviour of sexual harassment may amount to sexual harassment.

                          <ul>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            Implied or explicit promise of preferential treatment in employment; or
                            </li>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            Implied or explicit threat of detrimental treatment in employment; or
                            </li>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            Implied or explicit threat about her present or future employment status; or
                            </li>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            Interference with her work or creating an intimidating or offensive or hostile work environment for her; or
                            </li>
                            <li style={{ listStyleType: 'lower-roman' }}>
                            Humiliating treatment likely to affect her health or safety.
                            </li>
                          </ul>

                        </li>{' '}
                              

                        </ul>
                    </CCardBody>
                      <CCardFooter>
                        I have thoroughly read the instruction and accepted the
                        policy.
                        <br />
                        {empStatus && empStatus.emp_policy_status !== 1 && (
                          <CSwitch
                            className={'mx-1'}
                            variant={'3d'}
                            color={'success'}
                            onChange={e => acceptpolicy(e, 17)}
                            labelOn={'\u2713'}
                            labelOff={'\u2715'}
                          />
                        )}
                      </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 17 ? null : 17)}
                    >
                      <h5 className="m-0 p-0 ">
                        NDA (Non-Disclosure Agreement){' '}
                        <i
                          className={
                            accordion === 17
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 17}>
                    <CCardBody>
                      <b className="text-left">Date :</b>{' '}
                      {policyData?.inserted_at
                        ? policyData?.inserted_at : policyData?.joining_date
                          ? moment(policyData?.joining_date).format('DD-MM-YYYY')
                          : moment().format('DD-MM-YYYY')}
                      <br />
                      <br />
                      <h5 className="text-center" style={{ color: 'black' }}>
                        <u>Non-Disclosure Agreement</u>
                      </h5>
                      <br />
                      This Agreement is entered into this date{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.joining_date
                          ? policyData.joining_date
                          : '_______'}
                      </b>{' '}
                      between <b>EduNextG India LLP </b> (hereinafter called the
                      ‘Company’) and Mr./Ms./Mrs.{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.first_name + ' ' + policyData.last_name}
                      </b>{' '}
                      , son/daughter of{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.emp_father_name}
                      </b>{' '}
                      an Indian inhabitant residing at{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.address || '_________'}
                      </b>{' '}
                      (herein after referred to as “Employee”) of the other part
                      on the below Terms and Conditions.
                      <br />
                      You
                      have been hired for a specific project which involves
                      critical client business knowhow and access to lot of
                      confidential knowledge about EduNextG India LLP as well as client
                      business critical data .<br />
                      This undertaking is signed to ensure the responsibilities
                      of both the parties understood well and adhered to, basis
                      below points :-
                      <br />
                      <b>1. Data/Information Confidentiality : </b>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          During and after employment, you will maintain
                          information confidentiality and will never disclose
                          any confidential data which belongs to company or its
                          clients outside EduNextG India LLP.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          You will abide the Data Security policy and any breach
                          will be dealt as per company policy and intellectual
                          policy guidelines of EduNextG India LLP and client .
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          Sharing or using of any confidential data for benefit
                          of self/ colleagues/ relatives or acquaintances for
                          any monitory or non-monitory benefits would be
                          considered as a breach of this undertaking .
                        </li>
                      </ul>
                      <b>2. Adherence to Company Policy : </b>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          {' '}
                          By accepting the employment, you will adhere to all
                          the company policies and any breach will be dealt as
                          per company policies. You must adhere to all HR
                          policies, IT policies, Operations policies and Finance
                          policies. Also, the terms and conditions mentioned in
                          your offer letter, appointment letter and code of
                          conduct must be strictly adhered and any breach will
                          be dealt as per company policies .
                        </li>
                      </ul>
                      <b>3. Relationship Disclosure : </b>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          If any of your relatives or family members are working
                          with client or its service providers or joins later in
                          future, you must disclose it to the EduNextG India LLP HR
                          Department in written through an email with immediate
                          effect .
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          It is given and understood that in case EduNextG India LLP finds
                          out through any other source about such relationship
                          or use of any such relationship for the benefit of
                          other it would be dealt with very strictly and legal
                          action can be taken against both .{' '}
                        </li>
                      </ul>
                      This agreement is governed by and shall be construed in
                      accordance with the laws of Indian Penal code. All the
                      disputes arising out of or in connection with this
                      appointment will be the exclusive jurisdiction of courts
                      at Jaipur only.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 18)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 18 ? null : 18)}
                    >
                      <h5 className="m-0 p-0 ">
                        Data Consent Form{' '}
                        <i
                          className={
                            accordion === 18
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 18}>
                    <CCardBody>
                      <h5 className="text-center" style={{ color: 'black' }}>
                        <u>Data Subject Consent Form</u>
                      </h5>
                      <br />I{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.first_name + ' ' + policyData.last_name}
                      </b>{' '}
                      have joined EduNextG India LLP as{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.designation}
                      </b>{' '}
                      from{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.joining_date}
                      </b>{' '}
                      and I give my consent on the below mentioned points.
                      <br />
                      <br />
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          I have read and understood the employee code of
                          conduct of EduNextG India LLP and will abide
                          by all the policies and code of conduct of the
                          organization.
                        </li>
                      </ul>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          I authorize EduNextG India LLP to share
                          my personal data which includes my name, address,
                          email address, telephone number and any other personal
                          data collected by EduNextG India LLP with the third-party
                          contractors/vendors for background verification, ID
                          card printing, gifting, magazine or any other document
                          printing purposes.
                        </li>
                      </ul>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          I authorize EduNextG India LLP to use my
                          photograph on their website, social media handles,
                          magazines, events or activities or for any other
                          official purposes.
                        </li>
                      </ul>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          I am aware that anytime if I want to withdraw my
                          consent from processing my personal information w.r.t
                          – point no 3. I have to mark the mail to -{' '}
                          <b>hr@en.com</b>.
                        </li>
                      </ul>
                      I am aware that under the Digital Personal Data Protection Act 2023, I have certain rights regarding my personal information, including the right to access, rectify, erase, restrict processing, and object to processing. By signing this consent form, I acknowledge that I have read and understood the contents of this form, the purpose of sharing my personal information, and my rights under the Personal Data Protection Act 2022.
                      <br /><br />
                      Name of Individual providing Consent :{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData?.first_name + ' ' + policyData?.last_name}
                      </b>
                      <br /> <br />
                      Address of Individual providing Consent :{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.address || ''}
                      </b>{' '}
                      <br /> <br />
                      Date of Signature :{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData?.inserted_at
                          ? policyData?.inserted_at : policyData?.joining_date
                            ? moment(policyData?.joining_date).format('DD-MM-YYYY')
                            : moment().format('DD-MM-YYYY')}
                      </b>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 19)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <BgvAuthorizationConsent
                  getSignFun={getSignFun}
                  empDetails={empDetails}
                  policyData={policyData}
                />
              </div>
              <br />
            </CCardBody>
            <CCardFooter>
                  {/* {!isOtpSent &&
                    empStatus &&
                    empStatus.emp_policy_status !== 1 && (
                      <button
                        type="button"
                        disabled={
                          policy1 & policy2 & policy3 & policy4 &&
                            policy5 &
                            policy6 &
                            policy7 &
                            policy8 &
                            policy9 &
                            policy10 &
                            policy11 &
                            policy12 &
                            policy13 &
                            policy14 &
                            policy15 &
                            policy16 &
                            policy17 &
                            policy18&
                            policy19
                            ? false
                            : true
                        }
                        onClick={sendOtp}
                        className="btn btn-primary mr-3 mt-2 float-right"
                      >
                        Verify
                      </button>
                    )} */}
                 
                  {empStatus && empStatus.emp_policy_status !== 1 && (
                    <>
                      <div className="row">
                        {/* <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            value={otp}
                            onChange={e => setOtp(e.target.value)}
                            placeholder="Enter Otp"
                          />
                        </div> */}
                        <div className="col-md-12">
                            <button
                              type="button"
                              disabled={
                                policy1 & policy2 & policy3 & policy4 &&
                                  policy5 &
                                  policy6 &
                                  policy7 &
                                  policy8 &
                                  policy9 &
                                  policy10 &
                                  policy11 &
                                  policy12 &
                                  policy13 &
                                  policy14 &
                                  policy15 &
                                  policy16 &
                                  policy17 &
                                  policy18&
                                  policy19
                                  ? false
                                  : true
                              }
                              onClick={Policyaccepted}
                              className="btn btn-primary float-right"
                            >
                              Submit
                            </button>
                            
                        </div>
                      </div>
                    </>
                  )}
            </CCardFooter>
          </CCard>
        )}
      </CCol>
      <ToastContainer />
    </CRow>
  );
};
export default PolicyDetails;
