import React, { useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CButton,
} from '@coreui/react';
import moment from 'moment';

const AttendanceLogsModal = props => {
  const {
    open,
    toggleModal,
    attendanceLogs,
    loadingData,
    totalWorkTime,
    totalBreakTime,
    timer,
  } = props;

  const renderLogs = () => {
    if (attendanceLogs?.logs?.length) {
      const list = attendanceLogs.logs.map((item, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{moment(item.date_time).format('DD-MM-YYYY')}</td>
            <td>{item.formated_time}</td>
            <td>{item.activity_name}</td>
            <td>{item.device_name}</td>
          </tr>
        );
      });

      return <tbody>{list}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <th
              colSpan={5}
              style={{ textAlign: 'center', color: 'red', fontSize: '15px' }}
            >
              {loadingData ? 'Please wait...' : 'No Records Found'}
            </th>
          </tr>
        </tbody>
      );
    }
  };

  return (
    <div className="">
      <CModal show={open} onClose={toggleModal} size="md" backdrop={false}>
        <CModalHeader closeButton>
          <CModalTitle>Attendance Logs</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <table className="table table-bordered table-condensed">
              <tbody>
                <tr>
                  <td className="text-center">Working Hours</td>
                  <td className="text-center">Break Time</td>
                </tr>
                <tr>
                  <th className="text-center">
                    {timer !== '00:00:00' ? timer : totalWorkTime}
                  </th>
                  <th className="text-center">{totalBreakTime}</th>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered table-condensed">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Activity Name</th>
                  <th>IN/OUT</th>
                </tr>
              </thead>
              {renderLogs()}
            </table>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};
export default AttendanceLogsModal;
