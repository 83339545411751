import React, { useState, useEffect } from 'react'
import SliderEmployeeinfo from './SliderEmployeeInfo'
import CtcGenerate from './salaryUpdatePayroll'
import API from 'src/utils/apiCalling'
import { config } from 'src/utils/apiUrl'
import { getLocalDataAsObject } from 'src/utils/CoustomStorage'
import moment from 'moment'
import { decryptValue } from 'src/utils/common'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useForceUpdate } from 'src/hooks';
import { CustButton, CustLoader } from 'src/reusable';
import swal from "sweetalert";
import {
    CButton,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CDataTable
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'

const SubmittedPayRun = () => {
  const forceUpdate = useForceUpdate();
    const history=useHistory()
    const api = new API()
    const [createdPayRunInfo, setCreatedPayRunInfo] = useState()
    const [employeePayRunInfo, setEmployeePayRunInfo] = useState()
    const [notificationInfo, setNotificationInfo] = useState()
    const [employeeDetail, setEmployeeDetail] = useState()
    const [empMail, setEmpMail] = useState(new Set())
    const [empId, setEmpId] = useState()
    const [salaryData, setSalaryData] = useState([])
    const [totalPayrollCost,setTotalPayRollCost]=useState()
    const [EmployeeNetPay,setEmployeeNetPay]=useState()
    const [holdPayrollCost, setHoldPayrollCost] = useState();
    const [holdNetPay, setHoldNetPay] = useState();
    const [totalEmployee,setTotalEmployee]=useState()
    const [salaryMonth,setSalaryMonth]=useState()
    const [allDepartment,setAllDepart]=useState([])
    const [details, setDetails] = useState([])
    const [isOpen,setIsOpen]=useState(false)
    const [isViewSalaryOpen, setIsViewSalaryOpen]=useState(false);
    const [selectedEmployeeDetails,setSelectedEmployeeDetails]=useState()
    const [isCheckedAll, setIsCheckedAll]=useState(false)
    const [earnings, setEarnings] = useState([]);
    const [deductions, setDeductions] = useState([]);
    const [loadingSalaryData, setLoadingSalaryData] = useState(false);
    const [empSalaryDetails, setEmployeeSalaryDetails] = useState({
        bonus: {},
        earnings: [],
        deductions: [],
        ctc: {},
        gross: {},
        total_employee: {},
        total_employer: {},
        total_ctc: {},
        take_home: {},
    })
const startOfMonth = moment().subtract(1, "months").startOf('month').format('YYYY-MM-DD');
const [payrollMonth,setPayrollMonth] = useState(moment(startOfMonth).format("MM"));
const [payrollYear, setpayrollYear] = useState(moment(startOfMonth).format("YYYY"));
const { month, year, button} = useParams();
const [fields, setFields] = useState([]);
const [fields1, setFields1] = useState([]);
const settingState = ()=>{
    if(month===undefined){
        setPayrollMonth(moment(startOfMonth).format("MM"));
    }else{
        setPayrollMonth(month);
}
if(year===undefined){
    setpayrollYear(moment(startOfMonth).format("YYYY"));
}else{
    setpayrollYear(year);
}
}

const getSalaryComponents = async() => {
    let result = await api.get(config.salaryComponents);
    if(result && result.code===200){
        console.log("ed",result);
      setEarnings(result?.data?.earnings);
      setDeductions(result?.data?.deductions);
    }
  }

useEffect(()=>{
    fieldSetting();
    getAllEmployeeSalary();
},[earnings, deductions])

  const fieldSetting = () => {
    let temp1 = [
        { label: '', key: 'select_all', _style: { width: '1%' }, filter:false, sorter:false},
        { label : "Employee Name", key: 'employee_name', _style: { width: '10%' } },
        { label : "Employee Id", key: 'id', _style: { width: '10%' } },
        { label: "Pay Days", key: 'paid_days', _style: { width: '10%' } },
        { label: "Basic", key: 'basic', _style: { width: '10%' } }
      ];
      let temp2 = [
        { label: '', key: 'select_all', _style: { width: '1%' }, filter:false, sorter:false},
        { label : "Employee Name", key: 'employee_name', _style: { width: '10%' } },
        { label : "Employee Id", key: 'id', _style: { width: '10%' } },
        { label: "Pay Days", key: 'paid_days', _style: { width: '10%' } },
        { label: "Basic", key: 'basic', _style: { width: '10%' } }
      ];
    for(let i=0;i<earnings.length;i++){
        temp1.push({label: earnings[i]?.payslip_name, key: earnings[i]?.name, _style: { width: '10%' }})
        temp2.push({label: earnings[i]?.payslip_name, key: earnings[i]?.name, _style: { width: '10%' }})
    }
    temp1.push({ label : "Gross Pay", key: 'encp_gross', _style: { width: '10%' } });
    temp2.push({ label : "Gross Pay", key: 'encp_gross', _style: { width: '10%' } });
    for(let i=0;i<deductions.length;i++){
        console.log("ded", deductions[i]);
        temp1.push({label: deductions[i]?.payslip_name, key: deductions[i]?.name, _style: { width: '10%' }})
        temp2.push({label: deductions[i]?.payslip_name, key: deductions[i]?.name, _style: { width: '10%' }})
    }
    temp1.push(
        { label : "Total Employee Contribution", key: 'total_employee_contribution', _style: { width: '10%' } },
    { label : "Total Employer Contribution", key: 'total_employer_contribution', _style: { width: '10%' } },
    { label: "Net Pay", key: "encp_net_pay", _style: { width: '10%' } },
        { label: "View Pay Slip", key: "pay_slip", _style: { width: '8%' } },
    { label: "Email Pay Slip", key: "email_pay_slip", _style: { width: '8%' } },
    {label: "View", key: "view_salary", _style: { width: '5%'},sorter: false,filter: false});
    temp2.push({ label : "Total Employee Contribution", key: 'total_employee_contribution', _style: { width: '10%' } },
    { label : "Total Employer Contribution", key: 'total_employer_contribution', _style: { width: '10%' } },
    { label: "Net Pay", key: "encp_net_pay", _style: { width: '10%' } },
        { label: "View Pay Slip", key: "pay_slip", _style: { width: '8%' } },
    { label: "Email Pay Slip", key: "email_pay_slip", _style: { width: '8%' } },
   );
    setFields(temp1);
    setFields1(temp2);
}

      const checkAllEmployees = (e)=> {
        const emp = empMail;
        salaryData.forEach((em) => {
            if(e.target.checked){
                emp.add(em.id);
            }else{
                emp.delete(em.id);
            }
        })
        console.log(">>>>>>>",emp);
        setEmpMail(emp);
        let salary = salaryData;
        salary[0].temp=1;
        setSalaryData(salary);
        forceUpdate();
      }
      
      const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
          newDetails.splice(position, 1)
        } else {
          newDetails = [...details, index]
        }
        setDetails(newDetails)
      }


    const getPayrunDetails = async () => {
        let data = {
            "payrun_id": getLocalDataAsObject("payRunID")
        }
        let result = await api.post(config.payrun, data)

        if (result && result.code === 200) {
            setCreatedPayRunInfo(result.data)
            setEmployeePayRunInfo(result?.data?.employees_Details)
        }

    }

    const getSalaryDetails = async (item) => {
        let data = {
            "month":moment(`${payrollYear}-${payrollMonth}`).format('YYYY-MM'),
            "type":"employee",
            "emp_id":item.id,
            "query_type":"salary_details"
        }
        let result = await api.get(config.generateViewSalarySheet,true, data);
        if(result && result.code === 200){
            setEmployeeSalaryDetails(result?.data[0])
            setIsViewSalaryOpen(true);
        }
    }

    const getPayrollNotification = async () => {
        let result = await api.get(config.notifications)
        if (result && result.code === 200) {
            setNotificationInfo(result?.data[0])
        } else {

        }
    }
    
    const submittedPayRun = async () => {
        let data = {
            "payrun_id": getLocalDataAsObject("payRunID"),
            "status": 1
        }
        let result = await api.post(config.payrunActive, data)
        if (result && result.code === 200) {

            getPayrollNotification()
        }

    }
    const deletePayRun = async () => {
        let data = {
            "payrun_id": getLocalDataAsObject("payRunID")
        }
        let result = await api.post(config.payrunDelete, data)
        if (result && result.code === 200) {
            alert(result && result.message)
        }

    }
    const getAllEmployeeSalary = async (type,departmentname) => {
        setLoadingSalaryData(true)
        if(month===undefined || year===undefined){
            setPayrollMonth(moment(startOfMonth).format("MM"));
            setpayrollYear(moment(startOfMonth).format("YYYY"))
        }else{
            setPayrollMonth(`${month}`);
            setpayrollYear(`${year}`);
    }
        let typeOfEmployeeSalary=type&&departmentname!="all"?type:"all"
        let param = typeOfEmployeeSalary === "all" ?
            {
                "type": typeOfEmployeeSalary,

                "month": moment(`${payrollYear}-${payrollMonth}`).format('YYYY-MM')


            }
            : typeOfEmployeeSalary === "department" ?
                {
                    "type": typeOfEmployeeSalary,
                    "department_name": departmentname,
                    "month": moment(`${payrollYear}-${payrollMonth}`).format('YYYY-MM')
                } :
                {
                    "type": typeOfEmployeeSalary,
                    " emp_id": empId,
                    "month": moment(`${payrollYear}-${payrollMonth}`).format('YYYY-MM')


                }


        let result = await api.get(config.viewMonthlySalary, true, param)
        if(typeOfEmployeeSalary==="all"){
            setTotalPayRollCost(result?.data?.payroll_cost)
            setHoldPayrollCost(result?.data?.hold_payroll_cost)
            setEmployeeNetPay(result?.data?.total_net_pay)
            setHoldNetPay(result?.data?.hold_net_pay)
            setSalaryMonth(result?.data?.year_month)
            setTotalEmployee(result?.data?.total_employees)

        }
        const updatedSalaryData = [];
        if(result?.data?.emp_data) {
            result?.data?.emp_data.forEach((elem)=> {
            updatedSalaryData.push({
                ...elem,
                "total_employee_contribution":decryptValue(elem?.total_employee_contribution),
                "total_employer_contribution":decryptValue(elem?.total_employer_contribution),
                "bonus":decryptValue(elem?.bonus),
                "encp_gross":decryptValue(elem?.gross),
                "encp_net_pay":decryptValue(elem?.net_pay),
                "encp_taxes":decryptValue(elem?.taxes),
                "basic":decryptValue(elem?.basic)
            })
            for(let j=0;j<earnings.length;j++){
                updatedSalaryData[updatedSalaryData.length-1][earnings[j]['name']] = elem[earnings[j]['name']] ? decryptValue(elem[earnings[j]['name']]) : 0;
            }
            for(let j=0;j<deductions.length;j++){
                updatedSalaryData[updatedSalaryData.length-1][deductions[j]['name']] = elem[deductions[j]['name']] ? decryptValue(elem[deductions[j]['name']]) : 0;
            }
        })
        setSalaryData(updatedSalaryData);
        setLoadingSalaryData(false);
    }


       

    }
    const getAllDepartment = async () => {
        let result = await api.get(config.getAllDepartmentList)
        result && result?.data && setAllDepart(result?.data)
    
      }
    useEffect(() => {
        settingState()
        getPayrunDetails()
        getPayrollNotification()
        getAllDepartment()
        getSalaryComponents()
    }, [])
    const handleDepartment=(value)=>{
      
        getAllEmployeeSalary("department",value)
        

    }
    const isViewModalOpen=()=>{
        setIsOpen(!isOpen)
      }
      const downloadAndEmailPaySlip=async(type,item)=>{
      
        let payload=type==="download"?
        {
            "type":"employee",
            "month":moment(`${payrollYear}-${payrollMonth}`).format('YYYY-MM'),
            "emp_id":item?.id
            }:
            {
            "type":"employee",
            "month":moment(`${payrollYear}-${payrollMonth}`).format('YYYY-MM'),
            "emp_id":item?.id,
            "query_type":"send_mail"

        }
        let result =await api.post(config.shareSalarySlip,payload)
        if(type==="download"){
            result&&result?.data&&result?.data?.filePath&&window.open(result?.data?.filePath, '_blank')
        }else{
            toast(`Salary Slip to ${item?.employee_name} sent successfully.`)
        }
        

      }

      const emailPaySlips=async()=>{
        let payload=
        {
            "emp_data":JSON.stringify([...empMail.values()]),
            "month":payrollMonth,
        }
        swal({
            html: true,
            title: "Are you sure?",
            text: `Are you sure to send salary slip to selected employees`,
            icon: "success",
            buttons: ["No, cancel it!", "Yes"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.saveSalarySlipNotification,payload);
                if (result && result.code == 200) {
                    toast(`Salary Slips will sent successfully.`)
                }
            }
        })
      }

      const addRemoveEmployee = (e,item) => {
        const emp = empMail;
        if(e.target.checked){
            emp.add(item.id);
        }else{
            emp.delete(item.id);
        }
        setEmpMail(emp);
        let salary = salaryData;
        setSalaryData(salary)
        forceUpdate();
        console.log("mail",empMail.has(item.id),emp);
      }
    
    return (
        <div>
            <div className='row rishab' style={{ fontSize: "20px", fontFamily: "" }}>

                <div className='col-md-12'>
                    <div className='d-md-flex justify-content-between align-items-center my-2'>
                        <div className='Regulr d-flex align-items-center mb-2'>
                            <h4 className='px-2 m-0'>Regular Payroll</h4>
                        </div>
                        {button === "true" && 
                        <div className='send-btn d-flex align-items-center'>
                            {notificationInfo?.status === "Draft" && 
                                <button className='btn Fontis16 rounded-1 text-white fw-semibold mx-2' style={{ backgroundColor: "#4E98FF" }} onClick={submittedPayRun}>Submit and Approve</button>
                            }
                        </div>
                        }

                    </div>
                    {/* <div className='py-2 bck-clr d-flex align-items-center mb-3' style={{ backgroundColr: "#400E0E" }}>
                        <i className="bi bi-exclamation-circle text-dang mx-2 red-clr" style={{ color: "#red" }}></i>

                        <p className='text-black Fontis14 p-0 m-0 '>This payment is overdue by 64 day(s). Please pay your employees immediately. If you have paid them already, approve this payroll and record the payment.</p>
                    </div> */}

                    <div className='row mb-3'>
                    <div className='col-md-4'>
                            <div className='card'>
                                <div className='card-body m-0 p-0 p-1 text-center'>
                                <p className='fs-5 m-0'><b style={{color:"#f0772de0"}}>₹</b> {totalPayrollCost}</p>
                                    <hr className='m-0 my-3'></hr>
                                    <p className='Fontis14'><b style={{color:"darkblue"}}>PAYROLL COST</b></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card'>
                                <div className='card-body m-0 p-0 p-1 text-center'>
                                <p className='fs-5 m-0'><b style={{color:"#f0772de0"}}>₹</b> {EmployeeNetPay}</p>
                                    <hr className='m-0 my-3'></hr>
                                    <p className='Fontis14'><b style={{color:"darkblue"}}>EMPLOYEES NET PAY</b></p>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card'>
                                <div className='card-body m-0 p-0 p-1 text-center'>
                                <p className='fs-5 m-0'>{new Date(createdPayRunInfo?.pay_date).getDate()} {`${moment(payrollMonth).add(1,'month').format("MMM")} ${new Date(createdPayRunInfo?.pay_date).getFullYear()}`}</p>
                                    <hr className='m-0 my-3'></hr>
                                    <p className='Fontis14'><b style={{color:"darkblue"}}>PAY DAY</b></p>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-md-4'>
                            <div className='bg-white'>
                                <h5 className='gry-text p-2 pt-3'>Taxes & Deductions</h5>
                                <table className='table' border='0'>
                                    <tbody>
                                        <tr className='border-bottom-0'>
                                            <td className='payrun-label gry-text fw-semibold p-2'>Taxes</td>
                                            <td className='text-end text-black'>₹ {createdPayRunInfo?.Taxes}</td>
                                        </tr>
                                        <tr className='bg-white border-0'>
                                            <td className='payrun-label gry-text fw-semibold'>Pre-Tax Deductions</td>
                                            <td className='text-end txt-blck'>₹ {createdPayRunInfo?.Pre_Tax_Deductions}</td>
                                        </tr>
                                        <tr>
                                            <td className='payrun-label gry-text fw-semibold'>Post-Tax Deductions</td>
                                            <td className='text-end text-black'>₹0.00</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div> */}

                    </div>
                    <div className='row mb-3'>
                    <div className='col-md-4'>
                            <div className='card'>
                                <div className='card-body m-0 p-0 p-1 text-center'>
                                <p className='fs-5 m-0'><b style={{color:"#f0772de0"}}>₹</b> {holdPayrollCost}</p>
                                    <hr className='m-0 my-3'></hr>
                                    <p className='Fontis14'><b style={{color:"darkblue"}}>HOLD PAYROLL COST</b></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card'>
                                <div className='card-body m-0 p-0 p-1 text-center'>
                                <p className='fs-5 m-0'><b style={{color:"#f0772de0"}}>₹</b> {holdNetPay}</p>
                                    <hr className='m-0 my-3'></hr>
                                    <p className='Fontis14'><b style={{color:"darkblue"}}>HOLD EMPLOYEES NET PAY</b></p>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card'>
                                <div className='card-body m-0 p-0 p-1 text-center'>
                                <p className='fs-5 m-0'>{totalEmployee}</p>
                                    <hr className='m-0 my-3'></hr>
                                    <p className='Fontis14'><b style={{color:"darkblue"}}>EMPLOYEES</b></p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                            {/* <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
                            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button> */}
                        </div>
                    </nav>

                    <div className='col-md-12'>
                        <div class="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                <nav className=" navbar-expand-lg navbar-light ">

                                    <div className="text-start my-2 d-md-flex justify-content-between align-items-center">
                                        <div className=" navbar-collapse" id="navbarNavDropdown">
                                            {/* <ul className="navbar-nav ">
                                                <li className="nav-item dropdown ">
                                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        All Employees
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                        <li><a className="dropdown-item" href="#">All Employees</a></li>
                                                        <li><a className="dropdown-item" href="#">Active Employees</a></li>
                                                        <li><a className="dropdown-item" href="#">Employees with LOP</a></li>
                                                        <li><a className="dropdown-item" href="#">Tax Overridden Employees</a></li>
                                                        <li><a className="dropdown-item" href="#">Skipped Employees</a></li>
                                                        <li><a className="dropdown-item" href="#">Salary Withheld Employees</a></li>
                                                        <li><a className="dropdown-item" href="#">Yet to pay Employees</a></li>
                                                        <li><a className="dropdown-item" href="#">Paid Employees</a></li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                                            <select  className="form-control" style={{width:"30%"}} onChange={(e)=>handleDepartment(e.target.value)}>
                                                <option hidden>Select</option>
                                                <option value="all">ALL Department</option>
                                                {allDepartment&&allDepartment.length>0&&allDepartment.map((item)=>(
                                                     <option value={item?.name}>{item?.name}</option>

                                                ))}
                                               
                                            </select>

                                            {/* <ul className="navbar-nav border border-secondary text-primary mx-md-3">
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle Fontis14 gry-text pe-3" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Search Employees
                                                    </a>
                                                    <ul className="dropdown-menu p-2" aria-labelledby="navbarDropdownMenuLink">
                                                        <input type="text" className='outline-no border-secondary'></input>
                                                        <li><a className='dropdown-item' href='#'> </a></li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                                        </div>

                                        <div className='Filter-rihgt d-flex align-items-center'>
                                            <div className='mx-2'>
                                            <CustButton
                                                type="button"
                                                name="ok"
                                                text=" Send Salary Slip to Selected"
                                                color="primary"
                                                onClick={emailPaySlips}
                                                className="btn-loading btn-ladda"
                                                icon="fa fa-envelope"
                                                isDisabled={false}
                                            />
                                            </div>
                                        </div>
                                    </div>

                                </nav>


                                <div className='table-responsive'>
                                {loadingSalaryData && <CustLoader size="lg" isShow={loadingSalaryData} />}
                                {!loadingSalaryData && <>
                                    <div className='mx-2' data-toggle="tooltip" title="Select all Employees" style={{fontSize:"15px",cursor:"pointer"}}>
                                                <input type="checkbox" onClick={(e)=>checkAllEmployees(e)}></input>&nbsp;Select All
                                    </div>
                                    <CDataTable
                                        items={salaryData}
                                        fields={button == "true" ? fields : fields1}
                                        // columnFilter
                                        tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                        // footer
                                        itemsPerPageSelect
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        selectable
                                        scopedSlots={{
                                            // 'show_details':
                                            //     (item, index) => {
                                            //         return (
                                            //             <td className="py-2">
                                            //                 <CButton
                                            //                     color="primary"
                                            //                     variant="outline"
                                            //                     shape="square"
                                            //                     size="sm"
                                            //                     onClick={() => { toggleDetails(index) }}
                                            //                 >
                                            //                     {details.includes(index) ? 'Hide' : 'Show'}
                                            //                 </CButton>
                                            //             </td>
                                            //         )
                                            //     },
                                                
                                                'select_all':(item)=>{
                                                    return(
                                                        <input type="checkbox" className='outline-no border-secondary' name="check_all" value={item.id} onChange={(e)=>addRemoveEmployee(e,item)} checked = {empMail.has(item.id)}></input>  
                                                    )
                                                },
                                                'pay_slip':
                                                (item)=>{
                                                    return( 
                                                        <i  style={{color:"red",fontSize:"20px",cursor:"pointer"}} className="fa fa-file-pdf-o" onClick={()=>downloadAndEmailPaySlip("download",item)} aria-hidden="true"></i>
                                                    )
                                                },
                                                'email_pay_slip':
                                                (item,index)=>{
                                                    return(
                                                        <td onClick={()=>downloadAndEmailPaySlip("email",item)}><i style={{fontSize:"20px",cursor:"pointer",color:"darkblue"}}  className="fa fa-envelope" aria-hidden="true"></i></td>
                                                    )
                                                },
                                                'view_salary':
                                                (item,index)=>{
                                                    return(<>
                                                         <CustButton
                                                         size="xs"
                                                         type="button"
                                                         name="ok"
                                                         text=""
                                                         color="primary"
                                                         onClick={()=>getSalaryDetails(item)}
                                                         className="btn-loading btn-ladda"
                                                         icon="fa fa-eye"
                                                         isDisabled={false}
                                                     />
                                                    </>)
                                                    
                                                }
                                        }}
                                        
                                    />
                                   </>} 
                                </div>

                            </div>
                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">..50.</div>
                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="modal fade" id="exampleModalTogglefil" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggleLabel">Filter By</h5>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                            <div className="p-3 text-start">

                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h5 className="m-0 me-1">Employee Type</h5>
                                    <div className="dropdown">
                                        <button className="btn border-secondary dropdown-toggle mx-3" type="button" id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            All Employees
                                        </button>
                                        <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 ">Payement Mode</h5>
                                    <div className="dropdown">
                                        <button className="btn border-secondary dropdown-toggle mx-3" type="button" id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            All Payment Modes
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="dropdown ">
                                    <button className="btn border-0 bg-transparent text-primary Fontis16 admores my-2" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <small className='Fontis16'><i class="bi bi-plus-circle-dotted pe-2"></i> More Filter</small>
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                                <hr></hr>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="btnse ">
                                        <button type="button" class="btn btn-primary ">Apply</button>
                                        <button type="button" class="btn text-black border-secondary bg-transparent mx-2">Save</button>

                                    </div>
                                    <div>
                                        <button className='text-primary border-0 bg-transparent Fontis16'>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <SliderEmployeeinfo offcanvasRight={"offcanvasRight"} employeeDetail={selectedEmployeeDetails} /> */}
            {/* model on  */}
            <CModal
                show={isViewSalaryOpen}
                onClose={() => setIsViewSalaryOpen(!isViewSalaryOpen)}
                size="xl"
            ><CModalBody>
                <CtcGenerate
                setIsViewSalaryOpen={setIsViewSalaryOpen}
              employeeDetail={empSalaryDetails}
            /> 
            </CModalBody>
            </CModal>
            <CModal
                show={isOpen}
                onClose={() => isViewModalOpen()}
                size="sm"
            >

                <CModalHeader closeButton>
                    <CModalTitle >Pay Slip</CModalTitle>
                </CModalHeader>
                <CModalBody>
                   <div className='row p-2'>
                    <button className='btn btn-primary col-md '>Download</button>
                    <button className='btn btn-primary col-md ml-2'>Email</button>
                   </div>

                </CModalBody>
                <CModalFooter>
                    <CButton color="danger" onClick={() => isViewModalOpen(!isOpen)}><i className="fa fa-times" aria-hidden="true"></i>   </CButton>{' '}

                </CModalFooter>
            </CModal>




<ToastContainer/>

        </div>

    )


}

export default SubmittedPayRun