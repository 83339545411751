import { CCard, CCardBody } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import TaskList from './TaskList';
import './taskbox.css';
import Lottie from 'react-lottie-player';
import moment from 'moment';
import ActionBtn from './ActionBtn';
import { isNull } from 'lodash';
import { CWidgetIcon } from '@coreui/react';
import { CIcon } from '@coreui/icons-react';

const DashBoard = () => {
  const [dynamicTabs, setDynamicTabs] = useState([]);
  const [tabData, setTabData] = useState({});
  const [totalCount, setTotalCount] = useState('');
  const [bodyData, setBodyData] = useState([]);
  const [isSubaCatgories, setIsSubCategories] = useState(false);
  const api = new API();

  const taskListFun = async () => {
    let result = await api.get(config.getTaskBox);
    if (result && result.code == 200) {
      setDynamicTabs(result?.data?.tabs);
      if (result?.data?.tabs && result?.data?.tabs?.length) {
        if (result.data.tabs[0]['is_sub_categories'] !== 0) {
          setIsSubCategories(true);
        }
      }
      setTabData(result?.data?.values);
      setTotalCount(result?.data?.total_count);
    }
  };
  const reloadTaskBox = async () => {
    let result = await api.get(config.getTaskBox);
    if (result && result.code == 200) {
      setDynamicTabs(result?.data?.tabs);
      setTabData(result?.data?.values);
      setTotalCount(result?.data?.total_count);
    }
  };
  const containSubCategories = val => {
    if (val) {
      setIsSubCategories(true);
    } else {
      setIsSubCategories(false);
    }
  };

  const getDataForBody = async () => {
    let result = await api.get(config.all_HRMS_status_details);
    if (result && result.code == 200) {
      setBodyData(result && result.data);
    }
  };

  const renderTab = () => {
    return (
      dynamicTabs?.length &&
      dynamicTabs.map((tabs, index) => (
        <>
          {index === 0 ? (
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                id={index}
                href={`#${tabs.object_key}`}
                onClick={e => containSubCategories(tabs?.is_sub_categories)}
              >
                <div className="iconGroup">
                  <img src={`../images/taskbox/${tabs.image}`} alt="" />
                </div>
                <div className="txtGroup">
                  <strong>{tabs?.title}</strong>
                  {tabs?.is_sub_categories !== 0 ? (
                    <div className="d-flex">
                      {tabs?.sub_categories?.length &&
                        tabs.sub_categories.map((subTab, index) => (
                          <>
                            {subTab.len !== 0 && (
                              <div>
                                <span>{subTab?.title}</span>
                                <b>{subTab?.len}</b>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  ) : (
                    <b>{tabs?.total_length}</b>
                  )}
                </div>
              </a>
            </li>
          ) : (
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                id={index}
                href={`#${tabs.object_key}`}
                onClick={e => containSubCategories(tabs?.is_sub_categories)}
              >
                <div className="iconGroup">
                  <img src={`../images/taskbox/${tabs.image}`} alt="" />
                </div>
                <div className="txtGroup">
                  <strong>{tabs?.title}</strong>
                  {tabs?.is_sub_categories !== 0 ? (
                    <div className="d-flex">
                      {tabs?.sub_categories?.length &&
                        tabs.sub_categories.map((subTab, index) => (
                          <>
                            {subTab.len !== 0 && (
                              <div>
                                <span>{subTab?.title}</span>
                                <b>{subTab?.len}</b>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  ) : (
                    <b>{tabs?.total_length}</b>
                  )}
                </div>
              </a>
            </li>
          )}
        </>
      ))
    );
  };

  const renderTabContent = () => {
    let ind = 0;
    return (
      dynamicTabs?.length &&
      dynamicTabs?.map((tabs, index) => {
        if (tabs?.len === 0) {
          ind = ind + 1;
        }
        return (
          <>
            {' '}
            {index === ind ? (
              <div
                className="tab-pane fade show active"
                id={tabs.object_key}
                role="tabpanel"
                key={index}
              >
                {isSubaCatgories ? (
                  <>
                    <ul className="nav nav-tabs innerTab">
                      {tabs?.sub_categories?.length &&
                        tabs.sub_categories.map((subTab, index1) => (
                          <>
                            {subTab.len !== 0 && (
                              <>
                                {index1 === 0 ? (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link active"
                                      data-toggle="tab"
                                      href={`#${subTab.query_type}`}
                                    >
                                      {subTab?.title}
                                    </a>
                                  </li>
                                ) : (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      data-toggle="tab"
                                      href={`#${subTab.query_type}`}
                                    >
                                      {subTab?.title}
                                    </a>
                                  </li>
                                )}
                              </>
                            )}
                          </>
                        ))}
                    </ul>
                    <div className="tab-content">
                      {tabs?.sub_categories?.length &&
                        tabs.sub_categories.map((subTab, index2) => (
                          <>
                            {index2 === 0 ? (
                              <>
                                <div
                                  className="tab-pane fade show active"
                                  id={subTab.query_type}
                                  key={index2}
                                >
                                  <div className="table-responsive taskTable">
                                    <table className="table">
                                      {tabData[tabs.object_key]?.length &&
                                        tabData[tabs.object_key].map(
                                          (data, index3) => (
                                            <>
                                              {subTab.query_type ===
                                                data.sub_name && (
                                                <tr>
                                                  <td>
                                                    <table className="w-100">
                                                      <tr>
                                                        <td width="70%">
                                                          <p>
                                                            {data?.query_type}
                                                          </p>
                                                          <div className="d-flex profileDiv">
                                                            <img
                                                              src={
                                                                data?.emp_img !==
                                                                  '' &&
                                                                !isNull(
                                                                  data?.emp_img,
                                                                )
                                                                  ? `https://glueple.com:3001/employee_profile_picture/${data?.emp_img}`
                                                                  : `../images/logo.png`
                                                              }
                                                              alt=""
                                                              style={{
                                                                height: '35px',
                                                                width: '35px',
                                                              }}
                                                            />
                                                            <b>
                                                              {data?.emp_name}
                                                            </b>
                                                          </div>
                                                        </td>
                                                        {/* <td>
                                                  <strong>Priority</strong>
                                                  <span className="text-danger">Urgent</span>
                                                </td> */}
                                                        <td width="15%">
                                                          <strong>
                                                            Generated on
                                                          </strong>
                                                          <span>
                                                            {moment(
                                                              data?.created_at,
                                                            ).format(
                                                              'DD-MM-YYYY HH:mm A',
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          className="text-center"
                                                          width="15%"
                                                        >
                                                          {tabs?.object_key !==
                                                            'interview_round' && (
                                                            <ActionBtn
                                                              item={data}
                                                              bodyData={
                                                                bodyData
                                                              }
                                                              reloadTaskBox={
                                                                reloadTaskBox
                                                              }
                                                              tabs={tabs}
                                                            />
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          ),
                                        )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="tab-pane fade"
                                  id={subTab.query_type}
                                  key={index2}
                                >
                                  <div className="table-responsive taskTable">
                                    <table className="table">
                                      {tabData[tabs.object_key]?.length &&
                                        tabData[tabs.object_key].map(
                                          (data, index3) => (
                                            <>
                                              {subTab.query_type ===
                                                data.sub_name && (
                                                <tr>
                                                  <td>
                                                    <table className="w-100">
                                                      <tr>
                                                        <td width="70%">
                                                          <p>
                                                            {data?.query_type}
                                                          </p>
                                                          <div className="d-flex profileDiv">
                                                            <img
                                                              src={
                                                                data?.emp_img !==
                                                                  '' &&
                                                                !isNull(
                                                                  data?.emp_img,
                                                                )
                                                                  ? `https://glueple.com:3001/employee_profile_picture/${data?.emp_img}`
                                                                  : `../images/logo.png`
                                                              }
                                                              alt=""
                                                              style={{
                                                                height: '35px',
                                                                width: '35px',
                                                              }}
                                                            />
                                                            <b>
                                                              {data?.emp_name}
                                                            </b>
                                                          </div>
                                                        </td>
                                                        {/* <td>
                                                  <strong>Priority</strong>
                                                  <span className="text-danger">Urgent</span>
                                                </td> */}
                                                        <td width="15%">
                                                          <strong>
                                                            Generated on
                                                          </strong>
                                                          <span>
                                                            {moment(
                                                              data?.created_at,
                                                            ).format(
                                                              'DD-MM-YYYY HH:mm A',
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          className="text-center"
                                                          width="15%"
                                                        >
                                                          {tabs?.object_key !==
                                                            'interview_round' && (
                                                            <ActionBtn
                                                              item={data}
                                                              bodyData={
                                                                bodyData
                                                              }
                                                              reloadTaskBox={
                                                                reloadTaskBox
                                                              }
                                                              tabs={tabs}
                                                            />
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          ),
                                        )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="table-responsive taskTable">
                      <table className="table">
                        {tabData[tabs.object_key]?.length &&
                          tabData[tabs.object_key].map((data1, index) => (
                            <>
                              {tabs.object_key === data1.tab_name && (
                                <tr>
                                  <td>
                                    <table className="w-100">
                                      <tr>
                                        <td width="70%">
                                          <p>{data1?.query_type}</p>
                                          <div className="d-flex profileDiv">
                                            <img
                                              src={
                                                data1?.emp_img !== '' &&
                                                !isNull(data1?.emp_img)
                                                  ? `https://glueple.com:3001/employee_profile_picture/${data1?.emp_img}`
                                                  : `../images/logo.png`
                                              }
                                              alt=""
                                              style={{
                                                height: '35px',
                                                width: '35px',
                                              }}
                                            />
                                            <b>{data1?.emp_name}</b>
                                          </div>
                                        </td>
                                        {/* <td>
                                    <strong>Priority</strong>
                                    <span className="text-danger">Urgent</span>
                                  </td> */}
                                        <td width="15%">
                                          <strong>Generated on</strong>
                                          <span>
                                            {moment(data1?.created_at).format(
                                              'DD-MM-YYYY HH:mm A',
                                            )}
                                          </span>
                                        </td>
                                        <td className="text-center" width="15%">
                                          {tabs?.object_key !==
                                            'interview_round' && (
                                            <ActionBtn
                                              item={data1}
                                              bodyData={bodyData}
                                              reloadTaskBox={reloadTaskBox}
                                              tabs={tabs}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                      </table>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div
                className="tab-pane fade"
                id={tabs.object_key}
                role="tabpanel"
                key={index}
              >
                {isSubaCatgories ? (
                  <>
                    <ul className="nav nav-tabs innerTab">
                      {tabs?.sub_categories?.length &&
                        tabs.sub_categories.map((subTab, index1) => (
                          <>
                            {subTab.len !== 0 && (
                              <>
                                {index1 === 0 ? (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link active"
                                      data-toggle="tab"
                                      href={`#${subTab.query_type}`}
                                    >
                                      {subTab?.title}
                                    </a>
                                  </li>
                                ) : (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      data-toggle="tab"
                                      href={`#${subTab.query_type}`}
                                    >
                                      {subTab?.title}
                                    </a>
                                  </li>
                                )}
                              </>
                            )}
                          </>
                        ))}
                    </ul>
                    <div className="tab-content">
                      {tabs?.sub_categories?.length &&
                        tabs.sub_categories.map((subTab, index2) => (
                          <>
                            {index2 === 0 ? (
                              <>
                                <div
                                  className="tab-pane fade show active"
                                  id={subTab.query_type}
                                  key={index2}
                                >
                                  <div className="table-responsive taskTable">
                                    <table className="table">
                                      {tabData[tabs.object_key]?.length &&
                                        tabData[tabs.object_key].map(
                                          (data, index3) => (
                                            <>
                                              {subTab.query_type ===
                                                data.sub_name && (
                                                <tr>
                                                  <td>
                                                    <table className="w-100">
                                                      <tr>
                                                        <td width="70%">
                                                          <p>
                                                            {data?.query_type}
                                                          </p>
                                                          <div className="d-flex profileDiv">
                                                            <img
                                                              src={
                                                                data?.emp_img !==
                                                                  '' &&
                                                                !isNull(
                                                                  data?.emp_img,
                                                                )
                                                                  ? `https://glueple.com:3001/employee_profile_picture/${data?.emp_img}`
                                                                  : `../images/logo.png`
                                                              }
                                                              alt=""
                                                              style={{
                                                                height: '35px',
                                                                width: '35px',
                                                              }}
                                                            />
                                                            <b>
                                                              {data?.emp_name}
                                                            </b>
                                                          </div>
                                                        </td>
                                                        {/* <td>
                                                  <strong>Priority</strong>
                                                  <span className="text-danger">Urgent</span>
                                                </td> */}
                                                        <td width="15%">
                                                          <strong>
                                                            Generated on
                                                          </strong>
                                                          <span>
                                                            {moment(
                                                              data?.created_at,
                                                            ).format(
                                                              'DD-MM-YYYY HH:mm A',
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          className="text-center"
                                                          width="15%"
                                                        >
                                                          {tabs?.object_key !==
                                                            'interview_round' && (
                                                            <ActionBtn
                                                              item={data}
                                                              bodyData={
                                                                bodyData
                                                              }
                                                              reloadTaskBox={
                                                                reloadTaskBox
                                                              }
                                                              tabs={tabs}
                                                            />
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          ),
                                        )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="tab-pane fade"
                                  id={subTab.query_type}
                                  key={index2}
                                >
                                  <div className="table-responsive taskTable">
                                    <table className="table">
                                      {tabData[tabs.object_key]?.length &&
                                        tabData[tabs.object_key].map(
                                          (data, index3) => (
                                            <>
                                              {subTab.query_type ===
                                                data.sub_name && (
                                                <tr>
                                                  <td>
                                                    <table className="w-100">
                                                      <tr>
                                                        <td width="70%">
                                                          <p>
                                                            {data?.query_type}
                                                          </p>
                                                          <div className="d-flex profileDiv">
                                                            <img
                                                              src={
                                                                data?.emp_img !==
                                                                  '' &&
                                                                !isNull(
                                                                  data?.emp_img,
                                                                )
                                                                  ? `https://glueple.com:3001/employee_profile_picture/${data?.emp_img}`
                                                                  : `../images/logo.png`
                                                              }
                                                              alt=""
                                                              style={{
                                                                height: '35px',
                                                                width: '35px',
                                                              }}
                                                            />
                                                            <b>
                                                              {data?.emp_name}
                                                            </b>
                                                          </div>
                                                        </td>
                                                        {/* <td>
                                                  <strong>Priority</strong>
                                                  <span className="text-danger">Urgent</span>
                                                </td> */}
                                                        <td width="15%">
                                                          <strong>
                                                            Generated on
                                                          </strong>
                                                          <span>
                                                            {moment(
                                                              data?.created_at,
                                                            ).format(
                                                              'DD-MM-YYYY HH:mm A',
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          className="text-center"
                                                          width="15%"
                                                        >
                                                          {tabs?.object_key !==
                                                            'interview_round' && (
                                                            <ActionBtn
                                                              item={data}
                                                              bodyData={
                                                                bodyData
                                                              }
                                                              reloadTaskBox={
                                                                reloadTaskBox
                                                              }
                                                              tabs={tabs}
                                                            />
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          ),
                                        )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="table-responsive taskTable">
                      <table className="table">
                        {tabData[tabs.object_key]?.length &&
                          tabData[tabs.object_key].map((data1, index) => (
                            <>
                              {tabs.object_key === data1.tab_name && (
                                <tr>
                                  <td>
                                    <table className="w-100">
                                      <tr>
                                        <td width="70%">
                                          <p>{data1?.query_type}</p>
                                          <div className="d-flex profileDiv">
                                            <img
                                              src={
                                                data1?.emp_img !== '' &&
                                                !isNull(data1?.emp_img)
                                                  ? `https://glueple.com:3001/employee_profile_picture/${data1?.emp_img}`
                                                  : `../images/logo.png`
                                              }
                                              alt=""
                                              style={{
                                                height: '35px',
                                                width: '35px',
                                              }}
                                            />
                                            <b>{data1?.emp_name}</b>
                                          </div>
                                        </td>
                                        {/* <td>
                                    <strong>Priority</strong>
                                    <span className="text-danger">Urgent</span>
                                  </td> */}
                                        <td width="15%">
                                          <strong>Generated on</strong>
                                          <span>
                                            {moment(data1?.created_at).format(
                                              'DD-MM-YYYY HH:mm A',
                                            )}
                                          </span>
                                        </td>
                                        <td className="text-center" width="15%">
                                          {tabs?.object_key !==
                                            'interview_round' && (
                                            <ActionBtn
                                              item={data1}
                                              bodyData={bodyData}
                                              reloadTaskBox={reloadTaskBox}
                                              tabs={tabs}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                      </table>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        );
      })
    );
  };

  useEffect(() => {
    getDataForBody();
    taskListFun();
  }, []);

  return (
    <>
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="container mt-3">
            <div className="card border-0">
              <div className="card-body">
                <div className="name">
                  <h4>
                    {' '}
                    <strong>Overview</strong>
                  </h4>
                  <hr />
                  <ul className="nav nav-tabs mainTabGroup">
                    {renderTab() === 0 ? null : renderTab()}
                  </ul>
                  <div className="tab-content">
                    {renderTabContent() === 0
                      ? 'There are no new tasks'
                      : renderTabContent()}

                    {/* <div className="notifyAlrt">
                        <p>
                          <Lottie
                            src="../images/taskbox/bell-ico.json"
                            background="transparent"
                            speed="1"
                            style={{
                              width: '50px',
                              height: '50px',
                              marginLeft: '5px',
                            }}
                            loop
                            autoplay
                          ></Lottie>
                          Harish has requested a mouse on 30 Aug, 2023
                        </p>
                        <a href="javascript:void(0)" className="blueBtn">
                          Check out Now
                        </a>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <script
            src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"
            type="text/javascript"
          ></script>
          <script
            src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js"
            type="text/javascript"
          ></script>
          <script
            src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js"
            integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
            crossOrigin="anonymous"
          ></script>
          <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
        </div>
      </div>
          <TheFooter />
    </>
  );
};
export default DashBoard;
