import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';
import { toast, ToastContainer } from 'react-toastify';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { Button, Label, FormGroup, CustomInput } from 'reactstrap';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';
import appConstants from 'src/core/configs/Constants.config';
import {validateMobile} from '../../utils/ValidationHelper'

import {
  CButton,
  CModal,
  CModalBody,
  CCardFooter,
  CModalHeader,
  CCol,
  CForm,
  CFormGroup,
  CFormText,
  CTextarea,
  CInput,
  CLabel,
  CSelect,
  CRow,
  CModalTitle,
  CModalFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';

const NewSignupForm = (props) => {
  const api = new API(); 
  const { PG_location } = appConstants
  const { isOpen, isViewModalOpen,getAllEmp } = props;
  const [allDepartment, setAllDepartment] = useState();
  const [allDepartmentWiseRole, setAllDepartmentWiseRole] = useState();
  const [allEmpForReportingTo, setAllEmpForReportingTo] = useState();
  const [allCity, setAllCity] = useState();
  const [subcompanys, setSubCompany] = useState([]);
  const [company, setCompany] = useState([]);
  const [allWorkingFrom, setAllWorkingFrom] = useState();
  const [location, setLocation] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [roleId, setRoleId] = useState('');
  const [workingFrom, setWorkingFrom] = useState('office');
  const [reportedTo, setReportedTo] = useState('');
  const [reportedTo1, setReportedTo1] = useState('');
  const [reportedTo2, setReportedTo2] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [empId,setEmpId]=useState('')
  const [formValid, setFormValid] = useState(true);
  const [signupform, setsignupform] = useState({
    is_pf: 0,
    is_esi: 0,
    bonus: 0,
    first_name: '',
    last_name: '',
    mobile: '',
    email: '',
    joining_date: '',
  });
  useEffect(() => {
    getAllDepartment();
    getAllEmpForReportingTo();
    getAllWorkingFrom();

  }, []);

  const getAllDepartment = async () => {
    let result = await api.get(config.getAllDepartmentList);
    if (result && result.code == 200) {
      setAllDepartment(result?.data);
    }
  };

  const getAllWorkingFrom = async () => {
    let result = await api.get(config.getAllWorkingFrom);
    if (result && result.code === 200) {
      setAllWorkingFrom(result.data);
    }
  };

  const getAllDesignationByDpt = async deptId => {

    let data = {
      department_id: deptId,
    };
    let result = await api.get(config.getDepartmentWiseRoles, true, data);
    // console.log(result,"result setAllDepartmentWiseRole")

    if (result?.data?.length >0 && result.code == 200) {
      setAllDepartmentWiseRole(result.data);
    }
    else{
      setAllDepartmentWiseRole([])
      setRoleId('')
    }

  };

  const getAllEmpForReportingTo = async deptId => {
    let result = await api.get(config.getAllEmployeeList);

    if (result && result.code == 200) {
      setAllEmpForReportingTo(result.data);
    }
  };

  const onInputChange = async e => {
    const { name } = e.target;
    if (name === 'is_pf' || name === 'is_esi' || name === 'bonus') {
      let eligility = e.target.checked === true ? 1 : 0;
      setsignupform({ ...signupform, [name]: eligility });
    } else {
      setsignupform({ ...signupform, [name]: e.target.value });
    }

  };

  const changeDepartment = (e) => {
    setDepartmentId(e);
    getAllDesignationByDpt(e?.id);
    setRoleId('')
  }
  useEffect(()=>{
    if(isOpen==true)
    {
      setsignupform({
        is_pf: 0,
        is_esi: 0,
        bonus: 0,
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        joining_date: '',
      })
      setLocation('')
      setDepartmentId('')
      setRoleId('')
      setReportedTo2('')
      setReportedTo1('')
      setReportedTo('')
      setFormValid(true)
    }
    
  },[isOpen])
  



  const Submitformdata = async () => {
    let data = {
      emp_id: empId || '',
      is_pf: signupform.is_pf || 0,
      is_esi: signupform.is_esi || 0,
      // bonus: signupform.bonus || 0,
      first_name: signupform.first_name || '',
      last_name: signupform.last_name || '',
      mobile: signupform.mobile || '',
      email: signupform.email || '',
      joining_date: signupform.joining_date || '',
      location: location?.value || '',
      department_id: departmentId?.id || '',
      role_id: roleId?.id || '',
      reported_to: reportedTo?.id || '',
      reported_to1: reportedTo1?.id || '',
      reported_to2: reportedTo2?.id || '',
      // working_from: workingFrom?.category_value || '',
      working_from: workingFrom || 'office',
      // company_id: companyId?.id || '',
    };
  
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to submit details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.createEmployeeByAdmin, data);

        if (result && result.code == 200) {
          toast.success(result?.message);
          isViewModalOpen(!isOpen);
          getEmpID()
          getAllEmp()
        }else{
          toast.error(result?.message);
        }
      }
    });
  };
  const getAllCity = () => {
    let allCity = City.getCitiesOfCountry('IN');
    allCity.push(
      { name: 'Neemrana', countryCode: 'IN', stateCode: 'RJ' });
    setAllCity(allCity);
  };


  const getEmpID =async ()=>{
 
    let result = await api.get(config.getNextEmpId);
    if (result && result.code == 200) {
     
      setEmpId(result?.data);
    }else{
      // toast.error(result?.message);
    }
  }
  useEffect(() => {
    getAllCity();
    getEmpID();
  }, []);
      // Function to handle email input change
  const handleEmailChange = (e) => {

    const { value } = e.target;
    // Perform email validation
    let isValidEmail
  
    if(value == ''){
      console.log("yyypp")
      setFormValid(true)
      return
    }
    if(value){
     
       isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,4}$/.test(value);

    }
    console.log(isValidEmail,"isValidEmail")
    // Update form validity state
    setFormValid(isValidEmail);
  };

  

  return (
    <div className="">
      <CModal
        show={isOpen}
        onClose={() => isViewModalOpen(!isOpen)}
        size="lg">
        <CModalHeader closeButton>
          <CModalTitle>Add New Joining Details</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs="12">
              <CModalBody>
                <AvForm
                  action=""
                  method="post"
                  encType="multipart/form-data"
                  className="form-horizontal"
                
                >

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Employee ID <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvInput
                        name="emp_id"
                        id="Employee ID"
                        value={empId || ''}
                        disabled
                        // onChange={e => onInputChange(e)}
                        required
                        // placeholder="Enter Employee ID"
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Consider PF</CLabel>
                    </CCol>
                    <CCol md="8">
                      <AvField
                        type="checkbox"
                        name="is_pf"
                        id="Consider PF"
                        checked={signupform?.is_pf === 1 ? true : false}
                        onClick={e => onInputChange(e)}
                        label="PF"
                      ></AvField>
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Consider ESI</CLabel>
                    </CCol>
                    <CCol md="8">
                      <AvField
                        type="checkbox"
                        name="is_esi"
                        id="Consider ESI"
                        checked={signupform?.is_esi === 1 ? true : false}
                        onClick={e => onInputChange(e)}
                        label="ESI"
                      ></AvField>
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  {/* <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Consider Bonus</CLabel>
                    </CCol>
                    <CCol md="8">
                      <AvField
                        type="checkbox"
                        name="bonus"
                        id="Consider Bonus"
                        checked={signupform?.bonus === 1 ? true : false}
                        onClick={e => onInputChange(e)}
                        label="Bonus"
                      ></AvField>
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup> */}

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">First Name <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField 
                        name="first_name"
                        value={signupform?.first_name || ''}
                        id="First Name"
                        type="text"
                        placeholder="Enter First Name"
                        onChange={e => onInputChange(e)}
                        validate={{
                          // required: {value: true, errorMessage: 'First name is required'},
                          pattern: {value:  /^[a-zA-Z\s]+$/, errorMessage: 'First name field should be allowed alphabetic characters. '},
                          maxLength: {value: 16, errorMessage: 'Your name must 16 characters'}
                        }}
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Last Name <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField
                        name="last_name"
                        value={signupform?.last_name || ''}
                        id="Last Name"
                        onChange={e => onInputChange(e)}
                        placeholder="Enter Last Name"
                        validate={{
                          // required: {value: true, errorMessage: 'Last name is required'},
                          pattern: {value: /^[a-zA-Z\s]+$/, errorMessage: 'Last name field should be allowed alphabetic characters. '},
                          maxLength: {value: 16, errorMessage: 'Your name must 16 characters'}
                        }}
                        
                       
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Mobile No. <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField 
                        type="phone"
                        name="mobile"
                        value={signupform?.mobile || ''}
                        placeholder="Enter Mobile No."
                        id="Mobile No."
                        onChange={e => onInputChange(e)}
                        // required  
                        maxLength="10"
                        validate={{
                          // required: {value: true, errorMessage: 'Last name is required'},
                          pattern: {value: /^(?!.*(\d)\1{4})[6-9]\d{9}$/, errorMessage: 'Please enter a valid mobile number, It must be of 10 digits and begin with 6,7,8 or 9. '},
                          maxLength: {value: 10, errorMessage: ''}
                        }}
                       
                        
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Email ID <span className="required-field"></span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField
                        name="email"
                        value={signupform?.email || ''}
                        id="Email ID"
                        onChange={e => {onInputChange(e); handleEmailChange(e);}}
                        placeholder="Enter Email ID"
                        // required
                        validate={{
                          // required: {value: true, errorMessage: 'Please enter a name'},
                          pattern: {value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,4}$/, errorMessage: 'Pleace enter a valid email ID.'},
                         
                        }}
                        
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Joining Date <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField 
                        type="date"
                        name="joining_date"
                        value={signupform?.joining_date}
                        placeholder="Select Joining Date"
                        id="Joining Date"
                        onChange={e => onInputChange(e)}
                        // required
                        validate={{
                        date: {format: 'DD/MM/YYYY' ,errorMessage:'pleace enter a valid joining date formate DD/MM/YYYY'},
                        // required: {value: true, errorMessage: 'Joining Date required'},
                      }}
                       
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Location <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">

                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Location--"
                        onChange={setLocation}
                        value={location}
                        options={
                          PG_location 
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Department <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Department--"
                        onChange={e => changeDepartment(e)}
                        value={departmentId}
                        options={
                          allDepartment &&
                          allDepartment.length > 0 &&
                          allDepartment.map(val => {
                            val.label =
                              val.name;
                            val.value = val.id;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Designation <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder={allDepartmentWiseRole?.length > 0?"--Select Designation--":"--No Designation Available--"}
                        onChange={setRoleId}
                        value={roleId}
                        options={                         
                          allDepartmentWiseRole &&
                          allDepartmentWiseRole?.length > 0 &&
                          allDepartmentWiseRole.map(val => {
                            val.label =
                              val.role_name;
                            val.value = val.id;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Reporting Manager 1 <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">

                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Reporting Manager 1--"
                        onChange={setReportedTo}
                        value ={reportedTo}
                        options={
                          allEmpForReportingTo &&
                          allEmpForReportingTo.length > 0 &&
                          allEmpForReportingTo.map(val => {
                            val.label = val.first_name + ' ' + val.last_name + ' (' + val.emp_id + ')';
                            val.value = val.id;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Reporting Manager 2 <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        // required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Reporting Manager 2--"
                        onChange={setReportedTo1}
                        value={reportedTo1}
                        options={
                          allEmpForReportingTo &&
                          allEmpForReportingTo.length > 0 &&
                          allEmpForReportingTo.map(val => {
                            val.label = val.first_name + ' ' + val.last_name + ' (' + val.emp_id + ')';
                            val.value = val.id;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Reporting Manager 3 <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        // required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Reporting Manager 3--"
                        onChange={setReportedTo2}
                        value={reportedTo2}
                        options={
                          allEmpForReportingTo &&
                          allEmpForReportingTo.length > 0 &&
                          allEmpForReportingTo.map(val => {
                            val.label = val.first_name + ' ' + val.last_name + ' (' + val.emp_id + ')';
                            val.value = val.id;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  {/* <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Working From <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Working From--"
                        onChange={setWorkingFrom}
                        options={
                          allWorkingFrom &&
                          allWorkingFrom.length > 0 &&
                          allWorkingFrom.map(val => {
                            val.label = val.category_key;
                            val.value = val.category_value;
                            return val;
                          })
                        }
                      />
                    </CCol>
                  </CFormGroup> */}
                 
                </AvForm>
              </CModalBody>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
        <CButton
                    className="mr-2"
                    type="submit"
                    size="md"
                    color="primary"
                    disabled={!formValid} // Disable button if form is not valid
                    onClick={Submitformdata}
                  >
                    Submit
                  </CButton>
       
          <CButton color="danger" onClick={() => isViewModalOpen(!isOpen)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton>{' '}
        </CModalFooter>
      </CModal>
      <ToastContainer />
    </div>
  );
};
export default NewSignupForm;



