import React, { useState, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import {
  CBadge,
  CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup,
  CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel,
  CInput,
  CForm,
  CFormGroup,
  CFormText,
  CSelect,
  CCallout,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import ModalViewImage from '../modalViewImage';
import ModalAadharCardVerify from '../modalAadharCardVerify';
import { ToastContainer, toast } from 'react-toastify';
import {getPermissions, isPermission} from '../../../utils/common';

let All_Doc_Render = [
  {
    name: 'Aadhaar Card Front',
    doc_name: 'aadhar_card',
    is_verify: true,
    condition: 'required',
  },
  {
    name: 'Aadhaar Card Back',
    doc_name: 'aadhar_card_back',
    is_verify: true,
    condition: 'required',
  },
  { name: 'Passport Front', doc_name: 'passport', condition: 'optional' },
  { name: 'Passport Back', doc_name: 'passport_back', condition: 'optional' },
  { name: 'Pancard', doc_name: 'pancard', condition: 'required' },
  {
    name: 'Driving License',
    doc_name: 'driving_license',
    condition: 'optional',
  },
  // {name : "POLICE VERIFICATION CERTIFICATE OR PASSPORT",  doc_name: 'aadhar_card' },
  { name: 'Resume', doc_name: 'resume', condition: 'required' },
  {
    name: 'Passport Size Photo',
    doc_name: 'passport_size_photo',
    condition: 'required',
  },
  {
    name: '10th Marksheet',
    doc_name: 'secondary_marksheet',
    condition: 'optional',
  },
  {
    name: '12th Marksheet',
    doc_name: 'senior_secondary_marksheet',
    condition: 'optional',
  },
  {
    name: 'ITI Marksheets & Final Passing Certificate',
    doc_name: 'ITI_marksheet',
    condition: 'optional',
  },
  {
    name: 'Diploma Marksheets & Final Passing Certificate',
    doc_name: 'diploma_marksheet',
    condition: 'optional',
  },
  {
    name: 'Graduation Marksheets & Degree Certificate',
    doc_name: 'graduation_marksheet',
    condition: 'optional',
  },
  {
    name: 'Post Graduation Marksheets & Degree Certificate',
    doc_name: 'post_graduation_marksheet',
    condition: 'optional',
  },
  {
    name: 'Bank’s Cancel Cheque or Passbook',
    doc_name: 'bank_passbook',
    condition: 'required',
  },
  {
    name: 'Previous Company’s Experience Letter',
    doc_name: 'previous_company_experience_letter',
    condition: 'optional',
  },
  {
    name: 'Previous Company’s Payslips',
    doc_name: 'previous_company_payslip',
    condition: 'optional',
  },
  {
    name: 'Police Verification',
    doc_name: 'police_verification',
    condition: 'optional',
  },
];

const DocumentUpload = props => {
  const {getEmployeeDetailStatus,empDetails, onClickActive } = props;
  const api = new API();
  const [documentStatus, setDocumentStatus] = useState();
  const [docName, setDocName] = useState('');
  const [fileUpoad, setFileUpoad] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [employeeDocuments, setEmployeeDocuments] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [canDeletePermission, setCanDeletePermission] = useState(false);
  const [allDocumentsDetails, setAllDocumentsDetails] = useState([]);
  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };
  useEffect(() => {
    setAllDocumentsDetails(All_Doc_Render);
    getDocumentStatus();
    getEmployeeDocuments();
    isCanDocDeletePermission();
    getRequiredDocumentDetails();
    // console.log("************************", empDetails)
  }, [empDetails]);

  const getDocumentStatus = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getDocuumentStatus, true, data);
    if (result && result.code == 200) {
      // console.log("document status is", result)
      if (result && result.data.length > 0) {
        setDocumentStatus(result.data[0]);
      }
    }
  };

  const getEmployeeDocuments = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getEmployeeDocuments, true, data);
    if (result && result.code == 200) {
      // console.log("document status is.......", result)
      if (result && result.code == 200 && result.data) {
        setEmployeeDocuments(result.data);
      }
    }
  };

  const uploadDocuments = async (e, doc) => {
    if (fileUpoad === '' || fileUpoad === undefined) {
      toast.warning('Please select file');
    } else {
      const formData = new FormData();
      formData.append('file', fileUpoad);
      formData.append('doc_name', doc.doc_name);
      formData.append(
        'user_id',
        empDetails && empDetails.id ? empDetails && empDetails.id : '',
      );
      swal({
        content: true,
        title: 'Are you sure?',
        text: `Are you sure to upload Document`,
        icon: 'success',
        buttons: ['No, cancel it!', 'Submit'],
        dangerMode: true,
      }).then(async function(isConfirm) {
        if (isConfirm) {
          let result = await api.putPostFileFun(
            config.uplodeDocFile,
            formData,
            true,
          );
          if (result && result.success === false) {
            toast.error('Only pdf, jpg and png type file is required');
          }
          if (result && result.code === 200) {
            setFileUpoad('');
            toast.success(result && result.message);
            getDocumentStatus();
            getEmployeeDocuments();
            getEmployeeDetailStatus();
          } else {
            getDocumentStatus();
            toast.error(result && result.message);
          }
        }
      });
    }
  };

  const onChangeInput = async (e, doc_name) => {
    // setIsDisabled(true)
    // setIsDisabledValue(doc_name)

    let files = e.target.files;
    console.log('files nameeee', doc_name);
    setFileUpoad(files[0]);
    setDocName(doc_name);
  };
  const isViewModalOpen = imagePath => {
    setImagePath(imagePath);
    setIsOpen(!isOpen);
  };
  const isViewModalOpen1 = () => {
    let aadhar_varified = false;
    setIsOpen1(!isOpen1);
    // api
    if (aadhar_varified == true) {
      // uploadDocuments(e, doc)
    }
  };
  const isCanDocDeletePermission = async () => {
    // let data = {
    //   type: "2"
    // }
    // let result = await api.get(config.getHrmsStaticKeywordDetails, true, data)
    // if (result && result.code == 200 && result.data && result.data.permission == true) {
      // console.log("upload document result is", result)
    //   setCanDeletePermission(true)
    // }
    if(isPermission('asset_request_for_new_joiner')){
      setCanDeletePermission(true);
    }
  };
  const deleteDocumentFun = async (fileName) => {
    let data = {
      emp_user_id: empDetails && empDetails.id,
      document_name: fileName,
    };
    swal({
      content: true,
      title: 'Are you sure?',
      text: `Are you sure to delete ${fileName}`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Delete'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.deleteEmployeeDocument, data);
        if (result && result.code == 200) {
          toast.success('Successfully delete');
          setFileUpoad('');
          getDocumentStatus();
          getEmployeeDocuments();
        } else {
          toast.warning('Unable to delete');
        }
      }
    });
  };

  const getRequiredDocumentDetails = async () => {
    // alert(empDetails.id)
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getRequiredDocumentDetails, true, data);
    if (result && result.code == 200 && result.data) {
      // console.log("----------------- additional required document ", result)
      let additionalDoc = result.data;
      // let additionalDoc = [
      //   { doc_name: "driving_license", is_required: true },
      //   { doc_name: "passport", is_required: true },
      //   { doc_name: "passport_back", is_required: true }
      // ]
      if (additionalDoc && additionalDoc.length > 0) {
        for (let i = 0; i < All_Doc_Render.length; i++) {
          additionalDoc.filter(additionalDoc => {
            // console.log("not last......................")
            if (All_Doc_Render[i].doc_name == additionalDoc.doc_name) {
              // alert("inside")
              All_Doc_Render[i].condition = 'required';
            }
          });
        }
      }
      // console.log("last......................")
      setAllDocumentsDetails(All_Doc_Render);
    }
  };

  return (
    <>
      <div>
        <div className="row">
          {allDocumentsDetails &&
            allDocumentsDetails.length > 0 &&
            allDocumentsDetails.map((doc, index) => (
              <div className="col-md-6" key="">
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label className="pt-2" htmlFor="exampleInputEmail1">
                        {doc.name}{' '}
                        {doc.condition === 'required' && (
                          <span style={{ color: 'red', fontSize: '15px' }}>
                            *
                          </span>
                        )}
                      </label>
                      <input
                        onChange={e => onChangeInput(e, doc.doc_name)}
                        type="file"
                        className="form-control"
                        id="customFile"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mt-4">
                      <>
                        {employeeDocuments &&
                          employeeDocuments.data.length > 0 &&
                          employeeDocuments.data.map((document, index) => (
                            <>
                              {document.doc_name == doc.doc_name && (
                                <>
                                  {document.file_name.includes('.pdf') ? (
                                    <>
                                      {/* <a href={employeeDocuments && employeeDocuments.root_path + "/" + document.file_name}
                                  onClick={() => isViewModalOpen(employeeDocuments && employeeDocuments.root_path + "/" + document.file_name)}
                                  >View PDF</a> */}
                                      <a
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          isViewModalOpen(
                                            employeeDocuments &&
                                              employeeDocuments.root_path +
                                                '/' +
                                                document.file_name,
                                          )
                                        }
                                      >
                                        <embed
                                          src={
                                            employeeDocuments &&
                                            employeeDocuments.root_path +
                                              '/' +
                                              document.file_name
                                          }
                                          height="50"
                                          width="80"
                                        ></embed>
                                        View PDF
                                        <br />
                                      {/* <button onClick={() => isViewModalOpen(employeeDocuments && employeeDocuments.root_path + "/" + document.file_name)} className='btn btn-info'>View PDF</button> */}
                                      </a>
                                    </>
                                  ) : (
                                    <img
                                      src={
                                        employeeDocuments &&
                                        employeeDocuments.root_path +
                                          '/' +
                                          document.file_name
                                      }
                                      alt="document"
                                      width="80"
                                      height="50"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        isViewModalOpen(
                                          employeeDocuments &&
                                            employeeDocuments.root_path +
                                              '/' +
                                              document.file_name,
                                        )
                                      }
                                    ></img>
                                  )}

                                  {canDeletePermission && (
                                    <i
                                      onClick={() =>
                                        deleteDocumentFun(doc.doc_name)
                                      }
                                      className="fa fa-trash text-danger"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                  {/* <CButton className="mt-1" color="secondary" >  <a href={employeeDocuments && employeeDocuments.root_path + "/" + document.file_name} download><i className="fa fa-download"></i></a></CButton> */}
                                  {/* <CButton className="mt-1" data-toggle="tooltip" data-placement="top" title="" color="info" onClick={() => isViewModalOpen(employeeDocuments && employeeDocuments.root_path + "/" + document.file_name)}> <i className="fa fa-eye"></i></CButton> */}
                                </>
                              )}
                            </>
                          ))}
                      </>
                      <>
                        <button
                          type="button"
                          disabled={doc.doc_name == docName ? false : true}
                          className="btn btn-primary mt-3"
                          onClick={e => uploadDocuments(e, doc)}
                        >
                          <i className="fa fa-paper-plane"></i>
                        </button>
                        {/* {doc.is_verify &&
                        <button className="btn btn-info mt-3" onClick={isViewModalOpen1}>Verify</button>
                      } */}
                      </>
                    
                    &nbsp; &nbsp;
                  </div>
                </div>
              </div>
            ))}
          <strong>
            {' '}
            <p>
              <br />
              Failure to submit the required documents (Educational, Experience
              (If any), Police Verification, Aadhaar Card with Date of Birth,
              father’s name mentioned on it) till the Date of Joining will
              declare the extension of your date of joining.
            </p>
            <p>
              I hereby authorize EduNextG India LLP or their representative to verify
              information presented on my Employee Form/Resume and procure an
              investigate report or consumer report for that purpose. I certify
              that the information furnished in this form filled-in by me in
              conjunction with my addresses, details are true to the best of my
              knowledge. I hereby grant authority to the bearer of this letter
              to access or to be provided with full details.
            </p>
            <p>
              I have thoroughly read above instructions and take full
              responsibility of it.
            </p>
          </strong>
          <div>
            <button
              className="btn btn-success float-right"
              type="button"
              onClick={e => onClickActive(10)}
            >
              Next <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <Alert
          isAlert={isAlert}
          isAlertToggle={isAlertToggle}
          alertMessage={alertMessage}
          isAlertToggleClose={isAlertToggleClose}
        />
      </div>
      <ModalViewImage
       isOpen={isOpen}
       isViewModalOpen={isViewModalOpen}
       imagePath={imagePath}
      />
      <ModalAadharCardVerify
        isOpen1={isOpen1}
        isViewModalOpen1={isViewModalOpen1}
      />
      <ToastContainer />
    </>
  );
};
export default DocumentUpload;
